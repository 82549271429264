import useSWR from 'swr';
import { Decimal } from 'decimal.js';
import numeral from 'numeral';
import { formatEther } from 'viem';
import React from 'react';
import { ethUsdBuyAPI } from '@/constants';

export type CoinBasePrice = {
  amount: string;
  currency: string;
  base: string;
};

export type CoinBasePriceResponse = {
  data: CoinBasePrice;
};

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export default function useCoinbase() {
  const { data: ethData } = useSWR<CoinBasePriceResponse>(
    ethUsdBuyAPI,
    fetcher
  );

  const formatEthToUsd = React.useCallback(
    (wei?: bigint | null) => {
      if (!ethData || !wei) {
        return formatUSD(0);
      }

      const cents = weiToCents(wei, ethData.data);
      return formatUSD(cents);
    },
    [ethData]
  );

  return {
    rate: ethData?.data,
    formatEthToUsd,
  };
}

export const formatUSD = (cents: number) => {
  const price = new Decimal(cents).div(100).toNumber();
  const formattedPrice = numeral(price).format() === 'NaN' ? 0 : price;
  return numeral(formattedPrice).format('$0,0.00');
};

export const weiToCents = (
  wei?: bigint | null,
  rate?: CoinBasePrice | null
): number => {
  if (!rate || !wei) {
    return 0;
  }
  const etherPrice = new Decimal(rate.amount);
  const etherValue = new Decimal(formatEther(wei));
  const cents = Math.ceil(etherValue.mul(etherPrice).mul(100).toNumber());

  return cents;
};
