import {
  DynamicUserProfile,
  useDynamicContext,
  useUserWallets,
} from '@dynamic-labs/sdk-react-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as RadixDialog from '@radix-ui/react-dialog';
import { default as BoringAvatar } from 'boring-avatars';
import { X } from 'feather-icons-react';
import { useAccount, useDisconnect } from 'wagmi';
import { useENSResolver } from '@/hooks';
import { instagramUrl, marketplaceUrl, twitterUrl } from '@/constants';
import { Avatar } from '@/components/primitives/Avatar';
import { Box, Button, Flex, Text } from '@/components/primitives';
import { FullscreenModal } from '@/components/common/FullscreenModal';
import { ConnectWalletButton } from '@/components/ConnectWalletButton';

const HamburgerMenu = () => {
  const { address: wagmiAddress } = useAccount();
  const {
    handleLogOut,
    setShowDynamicUserProfile,
    isAuthenticated,
    primaryWallet,
    // dev: by importing dynamic user, we trigger connect wallet if needed
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    user: dynamicUser,
  } = useDynamicContext();
  const userWallets = useUserWallets();

  const address =
    primaryWallet?.address ?? wagmiAddress ?? userWallets[0]?.address;

  const {
    avatar: ensAvatar,
    shortAddress,
    shortName: shortEnsName,
  } = useENSResolver(address);
  const { disconnect } = useDisconnect();

  const handleDisconnect = async () => {
    disconnect();
    await handleLogOut();
  };

  const trigger = (
    <Button
      css={{
        justifyContent: 'center',
        width: '44px',
        height: '44px',
        backgroundColor: '$gray3',
      }}
      type="button"
      size="small"
      color="gray3">
      <FontAwesomeIcon icon={faBars} width={16} height={16} />
    </Button>
  );

  return (
    <FullscreenModal trigger={trigger}>
      {' '}
      <Flex
        css={{
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
        <Flex
          css={{
            py: '$4',
            px: '$4',
            width: '100%',
            borderBottom: '1px solid $gray4',
          }}
          align="center"
          justify="between">
          <a href={marketplaceUrl}>
            <Box css={{ width: 46, height: 36, cursor: 'pointer' }}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src="https://res.cloudinary.com/niftykit/image/fetch/f_auto/https://storage.niftykit.com/ipfs/bafkreiay4gbdudm2ymep6luwinyj4sp63soljmjghxysouqo3qlw7n5osm"
                width={36}
                height={36}
                alt="Kaikai Kiki Marketplace"
              />
            </Box>
          </a>
          <RadixDialog.Close>
            <Flex
              css={{
                justifyContent: 'center',
                width: '44px',
                height: '44px',
                alignItems: 'center',
                borderRadius: 8,
                backgroundColor: '$gray3',
                color: '$gray12',
                '&:hover': {
                  backgroundColor: '$gray4',
                },
              }}>
              <X fill="black" stroke="black" width={16} height={16} />
            </Flex>
          </RadixDialog.Close>
        </Flex>
        {isAuthenticated ? (
          <Flex
            css={{
              flexDirection: 'column',
              justifyContent: 'flex-start',
              height: '100%',
              py: '$5',
              px: '$4',
            }}>
            <a
              href={`${marketplaceUrl}/profile/${address}`}
              className="nav-link">
              <Flex
                css={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  pb: '$4',
                }}>
                <Flex css={{ alignItems: 'center' }}>
                  {ensAvatar ? (
                    <Avatar size="medium" src={ensAvatar} />
                  ) : (
                    <BoringAvatar size={36} name={address} variant="marble" />
                  )}
                  <Text style="subtitle1" css={{ ml: '$2' }}>
                    {shortEnsName ? shortEnsName : shortAddress}
                  </Text>
                </Flex>
              </Flex>
            </a>
            <a href={marketplaceUrl} className="nav-link">
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  py: '$3',
                  width: '100%',
                }}>
                Home
              </Text>
            </a>
            <a
              href={`${marketplaceUrl}/collections/trending`}
              className="nav-link">
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  py: '$3',
                  width: '100%',
                }}>
                Collections
              </Text>
            </a>
            <a href={`${marketplaceUrl}/drops`} className="nav-link">
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  py: '$3',
                  width: '100%',
                }}>
                Drops
              </Text>
            </a>
            <a href={`/packs`} className="nav-link">
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  py: '$3',
                  width: '100%',
                }}>
                View My Cards
              </Text>
            </a>
            <a href={`${marketplaceUrl}/leaderboard`} className="nav-link">
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  py: '$3',
                  width: '100%',
                }}>
                Leaderboard
              </Text>
            </a>
            <a href={twitterUrl} className="nav-link">
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  py: '$3',
                  width: '100%',
                }}>
                Twitter
              </Text>
            </a>
            <a href={instagramUrl} className="nav-link">
              <Text
                style="subtitle1"
                css={{
                  borderBottom: '1px solid $gray4',
                  cursor: 'pointer',
                  py: '$3',
                  width: '100%',
                }}>
                Instagram
              </Text>
            </a>
            <Button
              size="large"
              css={{
                mt: '18px',
                justifyContent: 'center',
                fontFamily: 'Poppins-Bold',
              }}
              color="gray3"
              onClick={() => {
                setShowDynamicUserProfile(true);
              }}>
              Manage Wallets
            </Button>
            <Button
              size="large"
              css={{
                my: '18px',
                justifyContent: 'center',
                fontFamily: 'Poppins-Bold',
              }}
              color="primary"
              onClick={handleDisconnect}>
              Sign Out
            </Button>
            {/* <Wallet /> */}
            <DynamicUserProfile />
          </Flex>
        ) : (
          <Flex
            direction="column"
            justify="between"
            css={{
              height: '100%',
              pb: '$5',
              px: '$4',
            }}>
            <Flex direction="column">
              <a href={marketplaceUrl} className="nav-link">
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    py: '$3',
                    width: '100%',
                  }}>
                  Home
                </Text>
              </a>
              <a
                href={`${marketplaceUrl}/collections/trending`}
                className="nav-link">
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    py: '$3',
                    width: '100%',
                  }}>
                  Collections
                </Text>
              </a>
              <a href={`${marketplaceUrl}/drops`} className="nav-link">
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    py: '$3',
                    width: '100%',
                  }}>
                  Drops
                </Text>
              </a>
              <a href={`${marketplaceUrl}/leaderboard`} className="nav-link">
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    py: '$3',
                    width: '100%',
                  }}>
                  Leaderboard
                </Text>
              </a>
              <a href={twitterUrl} className="nav-link">
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    py: '$3',
                    width: '100%',
                  }}>
                  Twitter
                </Text>
              </a>
              <a href={instagramUrl} className="nav-link">
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    py: '$3',
                    width: '100%',
                  }}>
                  Instagram
                </Text>
              </a>
            </Flex>
            <Box css={{ mt: 24 }}>
              <ConnectWalletButton />
            </Box>
          </Flex>
        )}
      </Flex>
    </FullscreenModal>
  );
};

export default HamburgerMenu;
