import {
  useDynamicContext,
  useSwitchWallet,
  useUserWallets,
} from '@dynamic-labs/sdk-react-core';
import numeral from 'numeral';
import { FC, useCallback, useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { formatEther } from 'viem';
import Countdown from '@/components/Drops/Countdown';
import { MintCheckoutModal } from '@/components/Drops/MintCheckoutModal';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { Box, Button, Flex, Text } from '@/components/primitives';
import { ToastContext } from '@/context/ToastContextProvider';
import { useMounted, usePackContract } from '@/hooks';
import { analyticsBrowser } from '@/services/segment/browser';
import { marketplaceUrl } from '@/constants';

export const Drop: FC = () => {
  const [mintQty, setMintQty] = useState(1);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);

  const { isAuthenticated, setShowAuthFlow, primaryWallet } =
    useDynamicContext();
  const userWallets = useUserWallets();
  const switchWallet = useSwitchWallet();
  const { addToast } = useContext(ToastContext);
  const isMounted = useMounted();
  const isMobile = useMediaQuery({ maxWidth: 767 }) && isMounted;
  const {
    totalMinted,
    basePrice,
    isLoading,
    refetch,
    saleActive: isSaleActive,
    saleDuration,
    saleStartTime,
  } = usePackContract();
  const navigate = useNavigate();

  const handleOpenClick = () => {
    if (isAuthenticated && !primaryWallet && userWallets.length >= 1) {
      switchWallet(userWallets[0].id);
    }
    setConfirmationOpen(true);
  };

  const now = new Date();
  const startDate = saleStartTime ? new Date(saleStartTime * 1000) : undefined;
  const endDate =
    saleStartTime && saleDuration
      ? new Date((saleStartTime + saleDuration) * 1000)
      : undefined;
  const saleActive =
    startDate && endDate
      ? now > new Date(startDate) && now <= endDate && isSaleActive
      : false;
  const saleEnded = endDate ? now > new Date(endDate) : false;

  const totalPacks = totalMinted ?? 0;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubscribe = useCallback(async () => {
    if (isAuthenticated) {
      analyticsBrowser.track('Get Notified Next Drop');
      addToast?.({
        title: 'You will be notified for next drop!',
        // description: 'Please check your email for confirmation.',
        status: 'success',
      });
    } else {
      setShowAuthFlow(true);
    }
  }, [isAuthenticated, setShowAuthFlow, addToast]);

  return (
    <>
      {isLoading ? (
        <Flex justify="center" align="center" css={{ height: 650 }}>
          <LoadingSpinner />
        </Flex>
      ) : (
        <Box
          id="drops"
          css={{
            maxWidth: 1600,
            height: '100%',
            mx: 'auto',
            px: 8,
            pt: 16,
            '@media (min-width: 768px) and (max-width: 1199px)': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            },
            '@bp800': {
              px: '$5',
            },
            '@xl': {
              px: '$6',
            },
          }}>
          <Flex
            direction={isMobile ? 'column' : 'row'}
            justify="between"
            align="center"
            css={{ flexWrap: 'wrap', gap: '$3' }}>
            <Flex
              className="cards"
              direction="column"
              justify="start"
              align="center"
              css={{
                maxWidth: 300,
                mb: '$2',
                flex: 1,
                '@sm': { maxWidth: '100%', mb: '$4' },
                '@media (min-width: 768px)': {
                  maxHeight: 'calc(100dvh - 113px)',
                  overflowY: 'auto',
                },
              }}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                style={{
                  maxHeight: 720,
                  maxWidth: '100%',
                }}
                src={'/images/108-flowers-pack.jpg'}
                alt="108 flowers trading cards"
              />
            </Flex>

            <Flex
              direction="column"
              justify="center"
              align="center"
              css={{ flex: 1 }}>
              {saleEnded && (
                <Text
                  css={{
                    marginBottom: 8,
                    fontFamily: 'Poppins-Bold',
                    fontSize: 60,
                    lineHeight: '64px',
                    letterSpacing: '-4px',
                    textWrap: 'nowrap',
                    '@md': {
                      fontSize: 84,
                      lineHeight: '80px',
                    },
                    '@lg': {
                      fontSize: 100,
                      lineHeight: '100px',
                    },
                  }}>
                  MINT ENDED
                </Text>
              )}
              {saleActive && (
                <>
                  <Text
                    css={{
                      maxWidth: '600px',
                      marginBottom: 18,
                      color: '#808080',
                      fontSize: 18,
                      lineHeight: '26px',
                      textAlign: 'center',
                      whiteSpace: 'pre-wrap',
                      '@md': { fontSize: 20 },
                    }}>
                    Time remaining:{' '}
                    <Countdown inherit to={endDate} onComplete={refetch} />
                  </Text>
                  <Text
                    css={{
                      fontFamily: 'Poppins-Bold',
                      fontSize: 60,
                      lineHeight: '64px',
                      letterSpacing: '-4px',
                      textWrap: 'nowrap',
                      '@md': {
                        fontSize: 84,
                        lineHeight: '80px',
                      },
                      '@lg': {
                        fontSize: 100,
                        lineHeight: '100px',
                      },
                    }}>
                    MINT NOW
                  </Text>
                </>
              )}
              {!saleActive && !saleEnded && (
                <Countdown to={startDate} onComplete={refetch} />
              )}
              <Text
                css={{
                  marginBottom: 18,
                  fontFamily: 'Poppins-Bold',
                  fontSize: 36,
                  fontWeight: 700,
                  textAlign: 'center',
                  lineHeight: '40px',
                  '@md': {
                    marginTop: 12,
                    marginBottom: 12,
                    fontSize: 34,
                    lineHeight: '42px',
                  },
                }}>
                108 Flowers NFT
              </Text>
              <Text
                css={{
                  width: '100%',
                  maxWidth: '444px',
                  marginBottom: 24,
                  paddingBottom: 28,
                  color: '#000',
                  fontSize: 18,
                  lineHeight: '26px',
                  textAlign: 'center',
                  whiteSpace: 'pre-wrap',
                  borderBottom: '1px solid #EEEEEE',
                  '@md': { fontSize: 20 },
                }}>
                Packs Collected: {numeral(totalPacks ?? 0).format('0, 0')}
              </Text>
              {saleEnded && (
                <>
                  <Text
                    css={{
                      maxWidth: '600px',
                      marginBottom: 36,
                      fontSize: 18,
                      lineHeight: '26px',
                      textAlign: 'center',
                      whiteSpace: 'pre-wrap',
                      '@md': { fontSize: 20 },
                    }}>
                    Thanks for participating! Stay tuned for the next drop.
                  </Text>
                </>
              )}
              {saleActive && (
                <>
                  <Button
                    color="primary"
                    css={{
                      width: '100%',
                      maxWidth: '444px',
                      marginBottom: 24,
                      justifyContent: 'center',
                      textWrap: 'nowrap',
                    }}
                    disabled={!saleActive}
                    corners="rounded"
                    type="button"
                    onClick={handleOpenClick}>
                    Mint
                  </Button>
                  <a
                    href={`${marketplaceUrl}/drops/info-rules`}
                    style={{
                      width: '100%',
                      maxWidth: '444px',
                      marginBottom: 24,
                      justifyContent: 'center',
                    }}>
                    <Button
                      color="secondary"
                      css={{
                        width: '100%',
                        justifyContent: 'center',
                        textWrap: 'nowrap',
                      }}
                      corners="rounded"
                      type="button">
                      See Info & Rules
                    </Button>
                  </a>
                  <Text
                    style="body1"
                    as="p"
                    css={{ my: '$1', textAlign: 'center', fontSize: 14 }}>
                    Supply: Unlimited card packs, 5 cards per pack
                  </Text>
                  <Text
                    style="body1"
                    as="p"
                    css={{ mb: '$1', textAlign: 'center', fontSize: 14 }}>
                    List price: {formatEther(BigInt(basePrice ?? 0))} ETH per
                    pack
                  </Text>
                </>
              )}
            </Flex>
          </Flex>
          <Box css={{ mb: 36 }}></Box>
        </Box>
      )}
      {confirmationOpen && (
        <MintCheckoutModal
          open={confirmationOpen}
          loading={loading}
          mintQty={mintQty}
          onOpenChange={setConfirmationOpen}
          onUpdateMintQty={setMintQty}
          onCompleted={() => {
            refetch();
            navigate('/packs');
          }}
        />
      )}
    </>
  );
};
