import { getContract, Hex } from 'viem';
import { useQuery } from '@tanstack/react-query';
import { cardContractAddress } from '@/constants';
import { basePublicClient } from '@/utils/wagmi';
import { cardsAbi } from '@/utils/abis/cardsAbi';
import { Card } from '@/types/cards';

const TOTAL_CARDS = 228;

const getCardsContract = () =>
  getContract({
    address: cardContractAddress as Hex,
    abi: cardsAbi,
    client: { public: basePublicClient },
  });

export const useCards = (address?: Hex) => {
  const {
    data: contract,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['contract'],
    queryFn: () => {
      if (!basePublicClient) return null;
      return getCardsContract();
    },
    enabled: !!basePublicClient,
  });

  const { data: cards } = useQuery({
    queryKey: ['cards', address],
    queryFn: async () => {
      if (!contract) return null;
      if (!address) return null;

      const cardList: Card[] = Array.from(
        { length: TOTAL_CARDS },
        (_, index) => ({
          tokenId: BigInt(index + 1),
          quantity: 0n,
        })
      );

      const balancePromises = cardList.map((card) =>
        contract.read.balanceOf([address, card.tokenId])
      );

      const balances = await Promise.all(balancePromises);

      return cardList.map((card, index) => ({
        ...card,
        quantity: balances[index],
      }));
    },
    enabled: !!contract && !!address,
  });

  return {
    cards,
    isLoading,
    refetch,
  };
};
