import { styled } from '@/stitches.config';

export default styled('span', {
  color: '$gray12',
  fontFamily: '$body',
  letterSpacing: 0,

  variants: {
    style: {
      h2: {
        fontFamily: 'Poppins-Black',
        fontWeight: 800,
        fontSize: 48,
      },
      h3: {
        fontFamily: 'Poppins-Black',
        fontWeight: 800,
        fontSize: 32,
      },
      h4: {
        fontFamily: 'Poppins-Black',
        fontWeight: 800,
        fontSize: 24,
      },
      h5: {
        fontFamily: 'Poppins-Bold',
        fontWeight: 700,
        fontSize: 20,
      },
      h6: {
        fontFamily: 'Poppins-Bold',
        fontWeight: 700,
        fontSize: 16,
      },
      h7: {
        fontFamily: 'Poppins-Bold',
        fontWeight: 700,
        fontSize: 18,
      },
      subtitle1: {
        fontFamily: 'Poppins-Bold',
        fontWeight: 700,
        fontSize: 16,
      },
      subtitle2: {
        fontFamily: 'Poppins-SemiBold',
        fontWeight: 500,
        fontSize: 14,
      },
      subtitle3: {
        fontFamily: 'Poppins-SemiBold',
        fontWeight: 500,
        fontSize: 12,
      },
      body1: {
        fontWeight: 400,
        fontSize: 16,
      },
      body2: {
        fontWeight: 400,
        fontSize: 14,
      },
      body3: {
        fontWeight: 400,
        fontSize: 12,
      },
      tiny: {
        fontFamily: 'Poppins-SemiBold',
        fontWeight: 500,
        fontSize: 10,
        color: '$neutralSolidHover',
      },
    },
    color: {
      base: {
        color: '$textColor',
      },
      subtle: {
        color: '$gray11',
      },
      error: {
        color: '$red11',
      },
      errorLight: {
        color: '$errorText',
      },
      accent: {
        color: '$accentText',
      },
      success: {
        color: '$successAccent',
      },
      button: {
        color: '$buttonTextColor',
      },
    },
    italic: {
      true: {
        fontStyle: 'italic',
      },
    },
    ellipsify: {
      true: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
  },

  defaultVariants: {
    style: 'body1',
  },
});
