import { DynamicUserProfile } from '@dynamic-labs/sdk-react-core';
import { motion } from 'framer-motion';
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Button, Flex, Text } from '../primitives';
import { useMounted } from '@/hooks';
import LoadingSpinner from '@/components/common/LoadingSpinner';

type Props = {
  packsToOpen: string;
  enoughBalance?: boolean;
  isSmartWallet?: boolean;
  isRevealing?: boolean;
};

export const OpenPackModal: FC<Props> = ({
  enoughBalance = false,
  isRevealing = false,
  isSmartWallet = false,
  packsToOpen,
}) => {
  const isMounted = useMounted();
  const isMobile = useMediaQuery({ maxWidth: 767 }) && isMounted;

  return (
    <motion.div
      style={{
        backgroundColor: 'rgba(0,0,0,0.7)',
        position: 'fixed',
        inset: 0,
        zIndex: 5000,
      }}
      transition={{ duration: 0.5 }}
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      <Box
        css={{
          backgroundColor: '$neutralBg',
          borderRadius: 4,
          $$shadowColor: '$colors$gray7',
          boxShadow: 'box-shadow: 0px 2px 16px $$shadowColor',
          border: '1px solid $gray7',
          position: 'fixed',
          top: '12.5%',
          left: '50%',
          transform: 'translateX(-50%)',
          minWidth: 290,
          maxWidth: '90vw',
          maxHeight: '85vh',
          overflowY: 'auto',
          '&:focus': { outline: 'none' },
          zIndex: 5000,
          '@media(max-width: 600px)': {
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            border: 0,
          },
        }}
        style={{
          width: isMobile ? '90vw' : '700px',
          maxHeight: isMobile ? '85vh' : '90vh',
          fontFamily: 'Poppins',
        }}>
        <Flex
          direction="column"
          css={{
            gap: '$2',
            background: 'white',
          }}>
          <Flex
            justify="between"
            align="center"
            css={{
              py: 24,
              px: 24,
              gap: '$2',
              background: 'transparent',
            }}>
            <Text style="h5" css={{ fontSize: 21 }}>
              {enoughBalance || isSmartWallet ? (
                <>
                  {packsToOpen === 'one'
                    ? 'Opening Pack...'
                    : 'Opening Packs...'}
                </>
              ) : (
                <>Bridge Funds To Base</>
              )}
            </Text>
          </Flex>
          <Flex
            direction="column"
            css={{
              px: 24,
              pb: 32,
              background: 'white',
            }}>
            <Box css={{ mt: '$4' }}>
              {enoughBalance || isSmartWallet ? (
                <Text style="body1" as="p" css={{ my: '$4' }}>
                  {isRevealing
                    ? 'Please wait as this may take a few minutes.'
                    : 'Please check your wallet to confirm the transaction.'}
                </Text>
              ) : (
                <Text style="body1" as="p">
                  In order to open your Card Pack(s) you’ll need Base funds.
                  Would you like to bridge your funds to the Base Network?
                </Text>
              )}
            </Box>
            {enoughBalance || isSmartWallet ? (
              <Flex justify="center" align="center" css={{ height: 120 }}>
                <LoadingSpinner />
              </Flex>
            ) : (
              <Flex justify="center" align="center" css={{ mt: '$5' }}>
                <a
                  href="https://www.relay.link/bridge/base"
                  target="_blank"
                  rel="noreferrer"
                  style={{ width: '100%' }}>
                  <Button
                    color="primary"
                    css={{
                      mt: '$3',
                      width: '100%',
                      justifyContent: 'center',
                    }}>
                    Bridge Funds to Base <img src="/icons/external-link.svg" />
                  </Button>
                </a>
              </Flex>
            )}
          </Flex>
        </Flex>
        <DynamicUserProfile />
      </Box>
    </motion.div>
  );
};
