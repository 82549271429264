import {
  DynamicUserProfile,
  useDynamicContext,
  useUserWallets,
} from '@dynamic-labs/sdk-react-core';
import { default as BoringAvatar } from 'boring-avatars';
import { X } from 'feather-icons-react';
import { motion } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import { useENSResolver } from '@/hooks';
import { marketplaceUrl } from '@/constants';
import { Avatar } from '@/components/primitives/Avatar';
import { Box, Button, Flex, Text } from '@/components/primitives';

export const AccountSidebar: FC = () => {
  const { address: wagmiAddress } = useAccount();
  const { disconnect } = useDisconnect();
  const {
    handleLogOut,
    isAuthenticated,
    primaryWallet,
    setShowDynamicUserProfile,
    // dev: by importing dynamic user, we trigger connect wallet if needed
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    user: dynamicUser,
  } = useDynamicContext();
  const userWallets = useUserWallets();
  const address =
    primaryWallet?.address ?? wagmiAddress ?? userWallets[0]?.address;

  const {
    avatar: ensAvatar,
    shortAddress,
    shortName: shortEnsName,
  } = useENSResolver(address);
  const [open, setOpen] = useState(false);

  const handleDisconnect = async () => {
    disconnect();
    await handleLogOut();
  };

  useEffect(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      setOpen(false);
    }
  }, [isAuthenticated]);

  return (
    <Flex>
      <Flex align="center">
        <a href="/packs">
          <Button
            size="medium"
            css={{
              justifyContent: 'center',
              fontFamily: 'Poppins-Bold',
            }}
            color="primary">
            View My Cards
          </Button>
        </a>
        <Button
          css={{
            ml: 12,
            justifyContent: 'center',
          }}
          corners="circle"
          type="button"
          color="gray3"
          onClick={() => setOpen(true)}>
          {ensAvatar ? (
            <Avatar size="medium" src={ensAvatar} />
          ) : (
            <BoringAvatar size={44} name={address} variant="marble" />
          )}
        </Button>
      </Flex>
      {open && (
        <Flex className="box">
          <Box
            css={{
              backgroundColor: 'rgba(0,0,0,0.478)',
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 1,
            }}
            onClick={() => setOpen(false)}></Box>

          <motion.div
            style={{
              position: 'fixed',
              overflowY: 'auto',
              right: 0,
              top: 0,
              bottom: 0,
              transform: 'none',
              left: 'unset',
              width: 395,
              maxWidth: 395,
              minWidth: 395,
              maxHeight: '100vh',
              background: '#fcfcfd',
              border: 0,
              borderRadius: 0,
              zIndex: 5,
            }}
            transition={{ duration: 0.25 }}
            initial={{
              opacity: 0,
              right: '-200px',
            }}
            animate={{
              opacity: 1,
              right: 0,
            }}
            exit={{
              opacity: 0,
              right: '-200px',
            }}>
            <Flex direction="column" css={{ py: '$4', px: '$4' }}>
              <Button
                color="ghost"
                css={{ color: '$gray10', ml: 'auto', mr: 10 }}
                onClick={() => {
                  setOpen(false);
                }}>
                <X height={18} width={18} fill="black" stroke="black" />
              </Button>
              <Flex align="center" css={{ gap: '$3', ml: '$3' }}>
                <a href={`${marketplaceUrl}/profile/${address}`}>
                  {ensAvatar ? (
                    <Avatar size="large" src={ensAvatar} />
                  ) : (
                    <BoringAvatar size={52} name={address} variant="marble" />
                  )}
                </a>

                <Flex direction="column">
                  <Flex
                    align="center"
                    css={{
                      gap: 8,
                      color: '$gray11',
                      cursor: 'pointer',
                    }}>
                    <Text style="body1" css={{ fontFamily: 'Poppins-Bold' }}>
                      {shortEnsName ? shortEnsName : shortAddress}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Button
                size="large"
                css={{
                  mt: '18px',
                  justifyContent: 'center',
                  fontFamily: 'Poppins-Bold',
                }}
                color="gray3"
                onClick={() => {
                  setShowDynamicUserProfile(true);
                }}>
                Manage Wallets
              </Button>
              <Button
                size="large"
                css={{ mt: '24px', mb: '20px', justifyContent: 'center' }}
                color="primary"
                onClick={handleDisconnect}>
                Sign Out
              </Button>
              <DynamicUserProfile />
            </Flex>
          </motion.div>
        </Flex>
      )}
    </Flex>
  );
};
