import { FC } from 'react';
import { CSS } from '@stitches/react';
import {
  collectorTermsUrl,
  faqUrl,
  instagramUrl,
  MKCollection,
  privacyUrl,
  termsUrl,
  twitterUrl,
} from '@/constants';
import { Anchor, Box, Flex, Text } from '@/components/primitives';

type SectionTitleProps = {
  title: string;
};

const SectionTitle: FC<SectionTitleProps> = ({ title }) => (
  <Text style="subtitle1" css={{ fontSize: 20, color: 'white', mb: 8 }}>
    {title}
  </Text>
);

type SectionLinkProps = {
  name: string;
  href: string;
  styles?: CSS;
  sameWindow?: boolean;
};

const SectionLink: FC<SectionLinkProps> = ({
  name,
  href,
  sameWindow,
  styles,
}) => (
  <Anchor
    target={sameWindow ? '' : '_blank'}
    rel="noopener noreferrer"
    href={href}
    weight="medium"
    css={{ fontSize: 16, mt: 16, color: 'white', ...styles }}>
    {name}
  </Anchor>
);

const ProjectLink: FC<SectionLinkProps> = ({
  name,
  href,
  sameWindow,
  styles,
}) => (
  <Box css={{ mt: 16 }}>
    <Anchor
      target={sameWindow ? '' : '_blank'}
      rel="noopener noreferrer"
      href={href}
      weight="medium"
      css={{ fontSize: 16, mt: 16, color: 'white', ...styles }}>
      {name}
    </Anchor>
  </Box>
);

const socialLinks = [
  {
    name: 'Twitter',
    href: twitterUrl,
  },
  {
    name: 'Instagram',
    href: instagramUrl,
  },
];

const resourceLinks = [
  {
    name: 'FAQ',
    href: faqUrl,
  },
  {
    name: 'Privacy Policy',
    href: privacyUrl,
  },
  {
    name: 'Terms of Service',
    href: termsUrl,
  },
  {
    name: 'Collector Terms',
    href: collectorTermsUrl,
  },
];

export const Footer = ({ collections }: { collections: MKCollection[] }) => {
  const projectLinks: SectionLinkProps[] = collections?.map(
    (collection: MKCollection) => ({
      name: collection.name,
      href: collection.marketplaceUrl,
    })
  );

  return (
    <Box
      css={{
        marginTop: 36,
        p: '$5',
        '@lg': {
          p: '$6',
        },
        background: 'rgba(1,1,1,1)',
      }}>
      <Flex
        justify="start"
        wrap="wrap"
        css={{
          maxWidth: 1600,
          mx: 'auto',
          flexDirection: 'row',
          alignItems: 'flex-start',
          gap: 36,
          '@bp600': {
            flexDirection: 'row',
            gap: 100,
          },
        }}>
        <Flex
          direction="column"
          css={{
            maxWidth: '445px',
            alignItems: 'flex-start',
          }}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src="/home/mk-logos/mk-logo-sm.png"
            width={60}
            height={60}
            alt="Kaikai Kiki NFT Marketplace Logo"
            style={{ marginBottom: '12px' }}
          />
          <SectionTitle title="Kaikai Kiki Marketplace (Beta)" />
          <Flex css={{ gap: '$4', mt: 16 }}>
            <Text style="body1" css={{ fontSize: 16, color: 'white', mb: 8 }}>
              The official marketplace of Takashi Murakami and Kaikai Kiki
              digital collectibles and NFTs. Shop and discover official TM/KK
              collections.
            </Text>
          </Flex>
        </Flex>
        <Flex direction="column">
          <SectionTitle title="Collections" />
          {projectLinks?.map((props) => (
            <ProjectLink key={props.name} sameWindow={true} {...props} />
          ))}
        </Flex>
        <Flex css={{ gap: 60, '@bp600': { gap: 80 } }}>
          <Flex direction="column">
            <SectionTitle title="Social" />
            {socialLinks.map((props) => (
              <SectionLink key={props.name} {...props} />
            ))}
          </Flex>
          <Flex direction="column">
            <SectionTitle title="Resources" />
            {resourceLinks.map((props) => (
              <SectionLink key={props.name} sameWindow={true} {...props} />
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        direction="column"
        justify="between"
        css={{
          maxWidth: 1600,
          mx: 'auto',
          marginTop: 60,
          borderTop: '1px solid #232323',
          borderTopStyle: 'solid',
          pt: 24,
          '@lg': { marginTop: 90, flexDirection: 'row' },
        }}>
        <Box>
          <Text
            style="body1"
            css={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 16,
              color: 'white',
            }}>
            Powered by{' '}
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src="/nk-logo.svg"
              width={24}
              height={24}
              alt="Powered by NiftyKit"
              style={{ margin: '0 4px', display: 'inline-block' }}
            />{' '}
            <Anchor
              target="_blank"
              rel="noopener noreferrer"
              href="https://nftkt.io/kkmarketplace"
              weight="heavy"
              css={{
                fontFamily: 'Poppins-Bold',
                fontSize: 16,
                color: 'white',
              }}>
              NiftyKit
            </Anchor>
          </Text>
        </Box>
        <Box css={{ mt: 24, '@lg': { mt: 0 } }}>
          <Text style="body1" css={{ fontSize: 16, color: 'white', mb: 8 }}>
            ©Kaikai Kiki Co., Ltd. All Rights Reserved. ©Takashi Murakami/Kaikai
            Kiki Co., Ltd. All Rights Reserved.
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
