import { createBrowserRouter } from 'react-router-dom';
import { ErrorPage } from '@/routes/ErrorPage';
import { NoMatch } from '@/routes/NoMatch';
import { Root } from '@/routes/Root';
import { Drop } from '@/routes/Drop';
import { Packs } from '@/routes/Packs';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Drop />,
      },
    ],
  },
  {
    path: '/packs',
    element: <Root hideFooter />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/packs',
        element: <Packs />,
      },
    ],
  },
  {
    path: '*',
    element: <Root />,
    children: [
      {
        path: '*',
        element: <NoMatch />,
      },
    ],
  },
]);
