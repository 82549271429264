import { VirtuosoGrid } from 'react-virtuoso';

const VirtualizedGrid = ({ ...rest }) => {
  return (
    <VirtuosoGrid
      useWindowScroll
      initialItemCount={25}
      overscan={{
        main: 2000,
        reverse: 2000,
      }}
      {...rest}
    />
  );
};
export default VirtualizedGrid;
