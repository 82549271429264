import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from '@/components/Footer';
import Navbar from '@/components/navbar';
import { Box } from '@/components/primitives';
import { collections } from '@/constants';

type Props = {
  hideFooter?: boolean;
};

export const Root: FC<Props> = ({ hideFooter }) => {
  return (
    <>
      <Box
        css={{
          background: '$neutralBg',
          height: '100%',
          minHeight: '100vh',
          pt: 80,
        }}>
        <Box css={{ maxWidth: 4500, mx: 'auto' }}>
          <Navbar />
          <main>
            <Outlet />
          </main>
        </Box>
      </Box>

      {!hideFooter && <Footer collections={collections} />}
    </>
  );
};
