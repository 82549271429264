export type CardMetadata = {
  tokenId: number;
  rarity: 'C' | 'R' | 'SR' | 'UR' | 'SA';
};

export type RaritySummary = {
  C: number;
  R: number;
  SR: number;
  UR: number;
  SA: number;
};

export const cardsMetadata: CardMetadata[] = [
  { tokenId: 1, rarity: 'C' },
  { tokenId: 2, rarity: 'C' },
  { tokenId: 3, rarity: 'C' },
  { tokenId: 4, rarity: 'SR' },
  { tokenId: 5, rarity: 'C' },
  { tokenId: 6, rarity: 'R' },
  { tokenId: 7, rarity: 'C' },
  { tokenId: 8, rarity: 'C' },
  { tokenId: 9, rarity: 'R' },
  { tokenId: 10, rarity: 'C' },
  { tokenId: 11, rarity: 'R' },
  { tokenId: 12, rarity: 'C' },
  { tokenId: 13, rarity: 'C' },
  { tokenId: 14, rarity: 'C' },
  { tokenId: 15, rarity: 'C' },
  { tokenId: 16, rarity: 'R' },
  { tokenId: 17, rarity: 'R' },
  { tokenId: 18, rarity: 'R' },
  { tokenId: 19, rarity: 'C' },
  { tokenId: 20, rarity: 'C' },
  { tokenId: 21, rarity: 'R' },
  { tokenId: 22, rarity: 'SR' },
  { tokenId: 23, rarity: 'R' },
  { tokenId: 24, rarity: 'C' },
  { tokenId: 25, rarity: 'C' },
  { tokenId: 26, rarity: 'C' },
  { tokenId: 27, rarity: 'C' },
  { tokenId: 28, rarity: 'C' },
  { tokenId: 29, rarity: 'C' },
  { tokenId: 30, rarity: 'C' },
  { tokenId: 31, rarity: 'C' },
  { tokenId: 32, rarity: 'SR' },
  { tokenId: 33, rarity: 'R' },
  { tokenId: 34, rarity: 'C' },
  { tokenId: 35, rarity: 'C' },
  { tokenId: 36, rarity: 'C' },
  { tokenId: 37, rarity: 'SR' },
  { tokenId: 38, rarity: 'R' },
  { tokenId: 39, rarity: 'C' },
  { tokenId: 40, rarity: 'R' },
  { tokenId: 41, rarity: 'C' },
  { tokenId: 42, rarity: 'C' },
  { tokenId: 43, rarity: 'SR' },
  { tokenId: 44, rarity: 'C' },
  { tokenId: 45, rarity: 'C' },
  { tokenId: 46, rarity: 'C' },
  { tokenId: 47, rarity: 'C' },
  { tokenId: 48, rarity: 'C' },
  { tokenId: 49, rarity: 'C' },
  { tokenId: 50, rarity: 'C' },
  { tokenId: 51, rarity: 'C' },
  { tokenId: 52, rarity: 'SR' },
  { tokenId: 53, rarity: 'C' },
  { tokenId: 54, rarity: 'C' },
  { tokenId: 55, rarity: 'R' },
  { tokenId: 56, rarity: 'C' },
  { tokenId: 57, rarity: 'R' },
  { tokenId: 58, rarity: 'C' },
  { tokenId: 59, rarity: 'C' },
  { tokenId: 60, rarity: 'C' },
  { tokenId: 61, rarity: 'C' },
  { tokenId: 62, rarity: 'C' },
  { tokenId: 63, rarity: 'SR' },
  { tokenId: 64, rarity: 'C' },
  { tokenId: 65, rarity: 'C' },
  { tokenId: 66, rarity: 'C' },
  { tokenId: 67, rarity: 'C' },
  { tokenId: 68, rarity: 'SR' },
  { tokenId: 69, rarity: 'R' },
  { tokenId: 70, rarity: 'SR' },
  { tokenId: 71, rarity: 'C' },
  { tokenId: 72, rarity: 'C' },
  { tokenId: 73, rarity: 'R' },
  { tokenId: 74, rarity: 'C' },
  { tokenId: 75, rarity: 'C' },
  { tokenId: 76, rarity: 'C' },
  { tokenId: 77, rarity: 'R' },
  { tokenId: 78, rarity: 'R' },
  { tokenId: 79, rarity: 'R' },
  { tokenId: 80, rarity: 'C' },
  { tokenId: 81, rarity: 'R' },
  { tokenId: 82, rarity: 'C' },
  { tokenId: 83, rarity: 'C' },
  { tokenId: 84, rarity: 'C' },
  { tokenId: 85, rarity: 'C' },
  { tokenId: 86, rarity: 'C' },
  { tokenId: 87, rarity: 'C' },
  { tokenId: 88, rarity: 'C' },
  { tokenId: 89, rarity: 'C' },
  { tokenId: 90, rarity: 'C' },
  { tokenId: 91, rarity: 'R' },
  { tokenId: 92, rarity: 'C' },
  { tokenId: 93, rarity: 'SR' },
  { tokenId: 94, rarity: 'C' },
  { tokenId: 95, rarity: 'SR' },
  { tokenId: 96, rarity: 'C' },
  { tokenId: 97, rarity: 'C' },
  { tokenId: 98, rarity: 'R' },
  { tokenId: 99, rarity: 'C' },
  { tokenId: 100, rarity: 'C' },
  { tokenId: 101, rarity: 'R' },
  { tokenId: 102, rarity: 'R' },
  { tokenId: 103, rarity: 'C' },
  { tokenId: 104, rarity: 'R' },
  { tokenId: 105, rarity: 'C' },
  { tokenId: 106, rarity: 'SR' },
  { tokenId: 107, rarity: 'C' },
  { tokenId: 108, rarity: 'R' },
  { tokenId: 109, rarity: 'UR' },
  { tokenId: 110, rarity: 'UR' },
  { tokenId: 111, rarity: 'UR' },
  { tokenId: 112, rarity: 'UR' },
  { tokenId: 113, rarity: 'SA' },
  { tokenId: 114, rarity: 'SA' },
  { tokenId: 115, rarity: 'C' },
  { tokenId: 116, rarity: 'C' },
  { tokenId: 117, rarity: 'C' },
  { tokenId: 118, rarity: 'SR' },
  { tokenId: 119, rarity: 'C' },
  { tokenId: 120, rarity: 'R' },
  { tokenId: 121, rarity: 'C' },
  { tokenId: 122, rarity: 'C' },
  { tokenId: 123, rarity: 'R' },
  { tokenId: 124, rarity: 'C' },
  { tokenId: 125, rarity: 'R' },
  { tokenId: 126, rarity: 'C' },
  { tokenId: 127, rarity: 'C' },
  { tokenId: 128, rarity: 'C' },
  { tokenId: 129, rarity: 'C' },
  { tokenId: 130, rarity: 'R' },
  { tokenId: 131, rarity: 'R' },
  { tokenId: 132, rarity: 'R' },
  { tokenId: 133, rarity: 'C' },
  { tokenId: 134, rarity: 'C' },
  { tokenId: 135, rarity: 'R' },
  { tokenId: 136, rarity: 'SR' },
  { tokenId: 137, rarity: 'R' },
  { tokenId: 138, rarity: 'C' },
  { tokenId: 139, rarity: 'C' },
  { tokenId: 140, rarity: 'C' },
  { tokenId: 141, rarity: 'C' },
  { tokenId: 142, rarity: 'C' },
  { tokenId: 143, rarity: 'C' },
  { tokenId: 144, rarity: 'C' },
  { tokenId: 145, rarity: 'C' },
  { tokenId: 146, rarity: 'SR' },
  { tokenId: 147, rarity: 'R' },
  { tokenId: 148, rarity: 'C' },
  { tokenId: 149, rarity: 'C' },
  { tokenId: 150, rarity: 'C' },
  { tokenId: 151, rarity: 'SR' },
  { tokenId: 152, rarity: 'R' },
  { tokenId: 153, rarity: 'C' },
  { tokenId: 154, rarity: 'R' },
  { tokenId: 155, rarity: 'C' },
  { tokenId: 156, rarity: 'C' },
  { tokenId: 157, rarity: 'SR' },
  { tokenId: 158, rarity: 'C' },
  { tokenId: 159, rarity: 'C' },
  { tokenId: 160, rarity: 'C' },
  { tokenId: 161, rarity: 'C' },
  { tokenId: 162, rarity: 'C' },
  { tokenId: 163, rarity: 'C' },
  { tokenId: 164, rarity: 'C' },
  { tokenId: 165, rarity: 'C' },
  { tokenId: 166, rarity: 'SR' },
  { tokenId: 167, rarity: 'C' },
  { tokenId: 168, rarity: 'C' },
  { tokenId: 169, rarity: 'R' },
  { tokenId: 170, rarity: 'C' },
  { tokenId: 171, rarity: 'R' },
  { tokenId: 172, rarity: 'C' },
  { tokenId: 173, rarity: 'C' },
  { tokenId: 174, rarity: 'C' },
  { tokenId: 175, rarity: 'C' },
  { tokenId: 176, rarity: 'C' },
  { tokenId: 177, rarity: 'SR' },
  { tokenId: 178, rarity: 'C' },
  { tokenId: 179, rarity: 'C' },
  { tokenId: 180, rarity: 'C' },
  { tokenId: 181, rarity: 'C' },
  { tokenId: 182, rarity: 'SR' },
  { tokenId: 183, rarity: 'R' },
  { tokenId: 184, rarity: 'SR' },
  { tokenId: 185, rarity: 'C' },
  { tokenId: 186, rarity: 'C' },
  { tokenId: 187, rarity: 'R' },
  { tokenId: 188, rarity: 'C' },
  { tokenId: 189, rarity: 'C' },
  { tokenId: 190, rarity: 'C' },
  { tokenId: 191, rarity: 'R' },
  { tokenId: 192, rarity: 'R' },
  { tokenId: 193, rarity: 'R' },
  { tokenId: 194, rarity: 'C' },
  { tokenId: 195, rarity: 'R' },
  { tokenId: 196, rarity: 'C' },
  { tokenId: 197, rarity: 'C' },
  { tokenId: 198, rarity: 'C' },
  { tokenId: 199, rarity: 'C' },
  { tokenId: 200, rarity: 'C' },
  { tokenId: 201, rarity: 'C' },
  { tokenId: 202, rarity: 'C' },
  { tokenId: 203, rarity: 'C' },
  { tokenId: 204, rarity: 'C' },
  { tokenId: 205, rarity: 'R' },
  { tokenId: 206, rarity: 'C' },
  { tokenId: 207, rarity: 'SR' },
  { tokenId: 208, rarity: 'C' },
  { tokenId: 209, rarity: 'SR' },
  { tokenId: 210, rarity: 'C' },
  { tokenId: 211, rarity: 'C' },
  { tokenId: 212, rarity: 'R' },
  { tokenId: 213, rarity: 'C' },
  { tokenId: 214, rarity: 'C' },
  { tokenId: 215, rarity: 'R' },
  { tokenId: 216, rarity: 'R' },
  { tokenId: 217, rarity: 'C' },
  { tokenId: 218, rarity: 'R' },
  { tokenId: 219, rarity: 'C' },
  { tokenId: 220, rarity: 'SR' },
  { tokenId: 221, rarity: 'C' },
  { tokenId: 222, rarity: 'R' },
  { tokenId: 223, rarity: 'UR' },
  { tokenId: 224, rarity: 'UR' },
  { tokenId: 225, rarity: 'UR' },
  { tokenId: 226, rarity: 'UR' },
  { tokenId: 227, rarity: 'SA' },
  { tokenId: 228, rarity: 'SA' },
];

export const calculateRaritySummary = (cardIds: bigint[]): RaritySummary => {
  const summary: RaritySummary = { C: 0, R: 0, SR: 0, UR: 0, SA: 0 };

  cardIds.forEach((id) => {
    const metadata = cardsMetadata.find((card) => card.tokenId === Number(id));
    if (metadata) {
      summary[metadata.rarity]++;
    }
  });

  return summary;
};

export const getCardRarity = (cardId: number) => {
  const meatadata = cardsMetadata.find((card) => card.tokenId === cardId);
  return meatadata?.rarity;
};
