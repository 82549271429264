import { Minus, Plus } from 'feather-icons-react';
import { FC, useCallback, useEffect, useState } from 'react';
import numeral from 'numeral';
import { Flex, Input } from '@/components/primitives';

type Props = {
  quantity: number;
  onUpdateQuantity: (quantity: number) => void;
  maxQuantity?: number;
  minQuantity?: number;
};

const QuantitySelector: FC<Props> = ({
  quantity,
  onUpdateQuantity,
  minQuantity = 1,
  maxQuantity,
}) => {
  const [inputValue, setInputValue] = useState(Math.max(minQuantity, quantity));

  useEffect(() => {
    setInputValue(Math.max(minQuantity, quantity));
  }, [quantity, minQuantity]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.target.value.split(',').join(''));
      if (
        !isNaN(value) &&
        value >= minQuantity &&
        (!maxQuantity || value <= maxQuantity)
      ) {
        setInputValue(value);
        onUpdateQuantity(value);
      } else if (event.target.value === '') {
        setInputValue(minQuantity);
      }
    },
    [maxQuantity, minQuantity, onUpdateQuantity]
  );

  const handleBlur = useCallback(() => {
    if (inputValue < minQuantity) {
      setInputValue(minQuantity);
      onUpdateQuantity(minQuantity);
    } else if (maxQuantity && inputValue > maxQuantity) {
      setInputValue(maxQuantity);
      onUpdateQuantity(maxQuantity);
    } else {
      setInputValue(Math.floor(quantity));
      onUpdateQuantity(Math.floor(quantity));
    }
  }, [inputValue, maxQuantity, minQuantity, onUpdateQuantity, quantity]);

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) =>
    event.target.select();

  return (
    <Flex align="center">
      <Minus
        width={21}
        height={21}
        fill="black"
        stroke="black"
        disabled={quantity <= minQuantity}
        onClick={() => {
          if (quantity > minQuantity) {
            onUpdateQuantity(Math.floor(quantity) - 1);
            setInputValue(Math.floor(quantity) - 1);
          }
        }}
        style={{
          opacity: quantity <= minQuantity ? 0.5 : 1,
          cursor: quantity <= minQuantity ? 'not-allowed' : 'pointer',
        }}
      />{' '}
      <Input
        type="text"
        value={`${numeral(inputValue).format('0,0')}`}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        containerCss={{ mx: 10, minWidth: '60px' }}
        css={{
          textAlign: 'center',
          fontFamily: 'Poppins-Bold',
          fontSize: 21,
          minWidth: '3ch',
          maxWidth: '10ch',
          width: `${inputValue.toString().length}ch`,
        }}
      />{' '}
      <Plus
        width={21}
        height={21}
        fill="black"
        stroke="black"
        disabled={maxQuantity ? quantity >= minQuantity : false}
        onClick={() => {
          if (maxQuantity && quantity >= maxQuantity) {
            return;
          }
          onUpdateQuantity(Math.floor(quantity) + 1);
          setInputValue(Math.floor(quantity) + 1);
        }}
        style={{
          opacity: maxQuantity ? (quantity >= maxQuantity ? 0.5 : 1) : 1,
          cursor: maxQuantity
            ? quantity >= maxQuantity
              ? 'not-allowed'
              : 'pointer'
            : 'pointer',
        }}
      />
    </Flex>
  );
};

export default QuantitySelector;
