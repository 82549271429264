import { CrossmintPaymentElement } from '@crossmint/client-sdk-react-ui';
import {
  DynamicUserProfile,
  useDynamicContext,
  useUserWallets,
} from '@dynamic-labs/sdk-react-core';
import { Root as DialogRoot } from '@radix-ui/react-dialog';
import { useQuery } from '@tanstack/react-query';
import { EthereumRpcError } from 'eth-rpc-errors';
import { ArrowLeft, X } from 'feather-icons-react';
import {
  ComponentPropsWithoutRef,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { formatEther } from 'viem';
import { base, mainnet } from 'viem/chains';
import { useAccount, useWalletClient } from 'wagmi';
import { Box, Button, Flex, Text } from '../primitives';
import { handleError } from '@/utils/errors';
import { useCoinbase, useMounted, usePackContract } from '@/hooks';
import { ToastContext } from '@/context/ToastContextProvider';
import { crossmintCollectionId, crossmintProjectId } from '@/constants';
import { Dialog } from '@/components/primitives/Dialog';
import QuantitySelector from '@/components/common/QuantitySelector';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import CrossmintStatus from '@/components/Drops/CrossmintStatus';

type Props = {
  loading?: boolean;
  mintQty: number;
  open: ComponentPropsWithoutRef<typeof DialogRoot>['open'];
  onOpenChange: ComponentPropsWithoutRef<typeof DialogRoot>['onOpenChange'];
  onCompleted: () => void;
  onUpdateMintQty: (qty: number) => void;
};

type MintStatus =
  | 'confirmTransaction'
  | 'minting'
  | 'completed'
  | 'creditCard'
  | undefined;

const getTitle = (status: MintStatus) => {
  switch (status) {
    case 'confirmTransaction':
      return 'Confirm Transaction';
    case 'minting':
      return 'Minting in Progress...';
    case 'completed':
      return 'Minting Complete';
    case 'creditCard':
      return 'Credit Card Checkout';
    default:
      return 'Mint Summary';
  }
};

const formatDiscount = (discountBps: number | undefined) => {
  if (discountBps === undefined) {
    return '0%';
  }
  return `${discountBps / 100}%`;
};

export const MintCheckoutModal: FC<Props> = ({
  loading,
  mintQty,
  open,
  onOpenChange,
  onCompleted = () => {},
  onUpdateMintQty = () => {},
}) => {
  const isMounted = useMounted();
  const isMobile = useMediaQuery({ maxWidth: 767 }) && isMounted;
  const { addToast } = useContext(ToastContext);
  const {
    primaryWallet,
    isAuthenticated,
    setShowAuthFlow,
    user,
    walletConnector,
  } = useDynamicContext();
  const userWallets = useUserWallets();
  const { formatEthToUsd } = useCoinbase();
  const { isConnected, address: wagmiAddress, chain } = useAccount();
  const address =
    primaryWallet?.address ?? wagmiAddress ?? userWallets[0]?.address;
  const { data: wallet } = useWalletClient({
    chainId: base.id,
  });

  const {
    getMintFee,
    getTotalPrice,
    getDiscountBps,
    hasEnoughBalance,
    baseMint,
    crossChainMint,
    maxPerMint,
    hasEnoughBaseBalance,
    hasEnoughMainnetBalance,
  } = usePackContract();

  const [status, setStatus] = useState<MintStatus>();
  const [orderIdentifier, setOrderIdentifier] = useState<string | null>(null);
  const [hideMintSummaryStep, setHideMintSummaryStep] = useState(false);

  const { data: mintValues } = useQuery({
    queryKey: ['mintValues', address, mintQty],
    queryFn: async () => {
      const [mintFee, totalPrice, discountBps] = await Promise.all([
        getMintFee(mintQty),
        getTotalPrice(mintQty),
        getDiscountBps(mintQty),
      ]);

      const enoughBalance = totalPrice ? hasEnoughBalance(totalPrice) : true;

      return {
        mintFee,
        totalPrice,
        discountBps,
        enoughBalance,
      };
    },
    placeholderData: (previousData) => previousData,
  });

  useEffect(() => {
    setHideMintSummaryStep(false);
  }, [isAuthenticated]);

  const handleMint = useCallback(async () => {
    if (!isConnected || !isAuthenticated || !wallet) {
      setShowAuthFlow(true);
      setHideMintSummaryStep(true);
      return;
    }

    if (!mintValues?.totalPrice) {
      return;
    }

    try {
      setStatus('confirmTransaction');

      if (hasEnoughBaseBalance(mintValues?.totalPrice)) {
        // user has enough base
        if (
          walletConnector &&
          walletConnector.supportsNetworkSwitching() &&
          chain?.id !== base.id
        ) {
          await walletConnector.switchNetwork({ networkChainId: base.id });
        }
        await baseMint(
          wallet.account.address,
          BigInt(mintQty),
          mintValues?.totalPrice
        );
      } else if (hasEnoughMainnetBalance(mintValues?.totalPrice)) {
        // user does not have enough base, but has enough mainnet
        if (
          walletConnector &&
          walletConnector.supportsNetworkSwitching() &&
          chain?.id !== mainnet.id
        ) {
          await walletConnector.switchNetwork({ networkChainId: mainnet.id });
        }
        await crossChainMint(
          wallet.account.address,
          BigInt(mintQty),
          mintValues?.totalPrice
        );
      }

      setStatus('completed');
    } catch (error) {
      setStatus(undefined);
      addToast?.({
        title: 'Mint Failure',
        description: handleError(error as EthereumRpcError<unknown>),
        status: 'error',
      });
    }
  }, [
    addToast,
    baseMint,
    chain?.id,
    crossChainMint,
    hasEnoughBaseBalance,
    hasEnoughMainnetBalance,
    isAuthenticated,
    isConnected,
    mintQty,
    mintValues?.totalPrice,
    setShowAuthFlow,
    wallet,
    walletConnector,
  ]);

  return (
    <Dialog
      open={open}
      onPointerDownOutside={(e) => {
        // prevent closing the modal when clicking outside
        if (!hideMintSummaryStep) e.preventDefault();
      }}
      onOpenChange={onOpenChange}
      overlayProps={{
        style: { opacity: 0.7, backgroundColor: '#000' },
      }}
      style={{
        width: isMobile ? '90vw' : '700px',
        maxHeight: isMobile ? '92vh' : '98vh',
        fontFamily: 'Poppins',
        display: hideMintSummaryStep ? 'none' : '',
      }}>
      <Flex
        direction="column"
        css={{
          gap: '$2',
          background: 'white',
        }}>
        <Flex
          justify="between"
          align="center"
          css={{
            py: status === undefined ? 12 : 24,
            px: 24,
            gap: '$2',
            background: status === undefined ? '$gray2' : 'transparent',
          }}>
          <Text
            style="h5"
            css={{
              fontSize: 19,
              display: 'flex',
              alignItems: 'center',
              '@md': { fontSize: 21 },
            }}>
            {status === 'creditCard' && (
              <Box
                css={{ height: 24, marginRight: '$2', cursor: 'pointer' }}
                onClick={() => setStatus(undefined)}>
                <ArrowLeft />
              </Box>
            )}
            {getTitle(status)}
          </Text>
          <Box style={{ cursor: 'pointer' }}>
            <X
              width={status === undefined ? '20' : '28'}
              height={status === undefined ? '20' : '28'}
              fill="black"
              stroke="black"
              onClick={() => {
                onOpenChange?.(false);
              }}
            />
          </Box>
        </Flex>
        <Flex
          direction="column"
          css={{
            px: 24,
            pb: 32,
            background: 'white',
          }}>
          {status === undefined && (
            <>
              <Box css={{ mt: '$4', mb: '$4' }}>
                <Flex align="center">
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    src="/icons/108-icon.png"
                    alt="108 Flowers Icon"
                    width={80}
                    height={80}
                    style={{ marginRight: '12px' }}
                  />
                  <Box>
                    <Text style="h5" as="p" css={{ fontSize: 18 }}>
                      Takashi Murakami 108 Flowers
                    </Text>
                    <Text style="body1" css={{}}>
                      <Flex align="center">
                        NFT Card Pack (Soulbound) |{' '}
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img
                          src="/icons/base-icon.png"
                          alt="Base Icon"
                          width={21}
                          height={21}
                          style={{ margin: '0 6px' }}
                        />{' '}
                        Base
                      </Flex>
                    </Text>
                  </Box>
                </Flex>
              </Box>
              <Box css={{ mt: '$4', mb: '$4' }}>
                <Flex justify="between" align="center" css={{ mb: '$2' }}>
                  <Text style="h5" as="p" css={{ fontSize: 18 }}>
                    Quantity
                  </Text>
                  <QuantitySelector
                    quantity={mintQty}
                    onUpdateQuantity={onUpdateMintQty}
                    maxQuantity={maxPerMint}
                  />
                </Flex>
                <Flex justify="between" align="center" css={{ mb: '$2' }}>
                  <Text>Platform Fees</Text>
                  <Text>
                    {formatEther(mintValues?.mintFee ?? BigInt(0))} ETH
                  </Text>
                </Flex>
                <Flex justify="between" align="center" css={{ mb: '$2' }}>
                  <Text>Volume Discount</Text>
                  <Text>{formatDiscount(mintValues?.discountBps)}</Text>
                </Flex>
              </Box>
              <Box
                css={{
                  mb: '$4',
                  borderBottom: '1px solid #ccc',
                  position: 'relative',
                  marginLeft: '-24px',
                  marginRight: '-24px',
                }}></Box>

              <Flex justify="between" align="center" css={{ mb: '$2' }}>
                <Text style="h5" as="p" css={{ fontSize: 18 }}>
                  You Pay
                </Text>
                <Text style="h5" as="div" css={{ fontSize: 18 }}>
                  <Flex align="center">
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src="/icons/eth-icon-dark.svg"
                      alt="ETH icon"
                      style={{ marginRight: '6px' }}
                    />
                    {formatEther(mintValues?.totalPrice ?? BigInt(0))}
                  </Flex>
                </Text>
              </Flex>
              <Flex justify="end">
                <Text css={{ color: '$gray11' }}>
                  {formatEthToUsd(mintValues?.totalPrice)}
                </Text>
              </Flex>
              <Flex
                direction="column"
                css={{ gap: '$2', mt: '$4' }}
                justify="center">
                {isAuthenticated && (
                  <Button
                    color="primary"
                    disabled={loading}
                    size="medium"
                    css={{
                      width: '100%',
                      marginBottom: 16,
                      justifyContent: 'center',
                      textWrap: 'nowrap',
                    }}
                    corners="rounded"
                    type="button"
                    onClick={() => {
                      setStatus('creditCard');
                    }}>
                    {loading ? 'Loading...' : 'Mint With Credit Card'}
                  </Button>
                )}
                <Button
                  color="primary"
                  disabled={
                    loading || (isAuthenticated && !mintValues?.enoughBalance)
                  }
                  size="medium"
                  css={{
                    width: '100%',
                    marginBottom: 16,
                    justifyContent: 'center',
                    textWrap: 'nowrap',
                  }}
                  corners="rounded"
                  type="button"
                  onClick={handleMint}>
                  {loading
                    ? 'Loading...'
                    : isAuthenticated
                    ? 'Mint With ETH'
                    : 'Sign In To Mint'}
                </Button>
                {isAuthenticated && !mintValues?.enoughBalance && (
                  <Text
                    color="error"
                    css={{ fontSize: 11, textAlign: 'center' }}>
                    Insufficient Balance. Add funds or buy with credit card.
                  </Text>
                )}
                <Text css={{ fontSize: 11, textAlign: 'center' }}>
                  An additional fee will be added to Credit Card purchases.
                </Text>
              </Flex>
            </>
          )}
          {status === 'confirmTransaction' && (
            <>
              <Box css={{ mt: '$4' }}>
                <Text style="body1" as="p" css={{ my: '$4' }}>
                  Please check your wallet to confirm the transaction.
                </Text>
              </Box>
              <Flex justify="center" align="center" css={{ height: 120 }}>
                <LoadingSpinner />
              </Flex>
            </>
          )}
          {status === 'creditCard' && (
            <>
              <Flex
                justify="center"
                align="center"
                css={{
                  width: 'calc(100% - 60px)',
                  height: 120,
                  position: 'absolute',
                  top: 120,
                  zIndex: 1,
                }}>
                <LoadingSpinner />
              </Flex>
              <Flex
                direction="column"
                css={{
                  width: '100%',
                  height: '100%',
                  minHeight: 300,
                  position: 'relative',
                  zIndex: 1,
                }}>
                <CrossmintPaymentElement
                  projectId={crossmintProjectId}
                  collectionId={crossmintCollectionId}
                  emailInputOptions={{
                    show: true,
                  }}
                  recipient={{
                    email: user?.email,
                    wallet: address,
                  }}
                  mintConfig={{
                    type: 'erc-721',
                    totalPrice: formatEther(
                      mintValues?.totalPrice ?? BigInt(0)
                    ),
                    quantity: mintQty,
                    mintTo: address,
                  }}
                  onEvent={(event) => {
                    switch (event.type) {
                      case 'payment:process.succeeded':
                        setOrderIdentifier(event.payload.orderIdentifier);
                        setStatus('minting');
                        break;
                      default:
                        break;
                    }
                  }}
                />
              </Flex>
            </>
          )}
          {status === 'minting' && (
            <>
              <Box css={{ mt: '$4' }}>
                <Text style="body1" as="p" css={{ my: '$4' }}>
                  Please wait while your{' '}
                  {mintQty > 1 ? (
                    <>
                      <Text
                        css={{
                          fontFamily:
                            "'Poppins-Semibold', 'Poppins', Arial, Helvetica",
                          fontWeight: 500,
                        }}>
                        {mintQty} Takashi Murakami 108 Flowers NFT Card Packs
                      </Text>{' '}
                      are being minted. This may take a few minutes.
                    </>
                  ) : (
                    <>
                      <Text
                        css={{
                          fontFamily:
                            "'Poppins-Semibold', 'Poppins', Arial, Helvetica",
                          fontWeight: 500,
                        }}>
                        {mintQty} Takashi Murakami 108 Flowers NFT Card Pack
                      </Text>{' '}
                      is being minted. This may take a few minutes.
                    </>
                  )}
                </Text>
              </Box>
              <Flex justify="center" align="center" css={{ height: 120 }}>
                <LoadingSpinner />
              </Flex>

              {orderIdentifier && (
                <CrossmintStatus
                  orderIdentifier={orderIdentifier}
                  onFail={() => {
                    setOrderIdentifier(null);
                    setStatus(undefined);
                  }}
                  onSuccess={() => {
                    setOrderIdentifier(null);
                    setStatus('completed');
                  }}
                />
              )}
            </>
          )}
          {status === 'completed' && (
            <>
              <Box css={{ mt: '$4' }}>
                <Text style="body1" as="p" css={{ my: '$4' }}>
                  {mintQty > 1 ? (
                    <>
                      Your{' '}
                      <Text
                        css={{
                          fontFamily:
                            "'Poppins-Semibold', 'Poppins', Arial, Helvetica",
                          fontWeight: 500,
                        }}>
                        {mintQty} Takashi Murakami 108 Flowers NFT Card Packs
                      </Text>{' '}
                      have been minted! These packs are now available in your
                      wallet and can be viewed on your Kaikai Kiki Marketplace
                      profile.
                    </>
                  ) : (
                    <>
                      Your{' '}
                      <Text
                        css={{
                          fontFamily:
                            "'Poppins-Semibold', 'Poppins', Arial, Helvetica",
                          fontWeight: 500,
                        }}>
                        {mintQty} Takashi Murakami 108 Flowers NFT Card Pack
                      </Text>{' '}
                      has been minted! This pack is now available in your wallet
                      and can be viewed on your Kaikai Kiki Marketplace profile.
                    </>
                  )}
                </Text>
              </Box>
              <Flex
                direction="column"
                css={{ gap: '$2', mt: '$4' }}
                justify="center">
                <Button
                  color="primary"
                  size="medium"
                  css={{
                    width: '100%',
                    marginBottom: 16,
                    justifyContent: 'center',
                    textWrap: 'nowrap',
                  }}
                  corners="rounded"
                  type="button"
                  onClick={() => {
                    onOpenChange?.(false);
                    onCompleted();
                  }}>
                  Continue To Reveal
                </Button>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
      <DynamicUserProfile />
    </Dialog>
  );
};
