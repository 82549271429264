import { Hex } from 'viem';

export const isDev = !!import.meta.env.VITE_IS_DEV;
export const marketplaceUrl = import.meta.env.VITE_MARKETPLACE_URL;
export const packContractAddress = import.meta.env.VITE_PACK_CONTRACT_ADDRESS;
export const cardContractAddress = import.meta.env.VITE_CARD_CONTRACT_ADDRESS;
export const crossmintProjectId = import.meta.env.VITE_CROSSMINT_PROJECT_ID;
export const crossmintCollectionId = import.meta.env
  .VITE_CROSSMINT_COLLECTION_ID;
export const packStartBlock = Number(
  import.meta.env.VITE_PACK_START_BLOCK ?? 15799518
);
export const paymasterUrl = import.meta.env.VITE_PAYMASTER_PROXY_API_URL;
export const apiBaseUrl = import.meta.env.VITE_API_URL;
export const coinbaseEntryPoint = '0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789';

export const faqUrl = `${marketplaceUrl}/faq`;
export const instagramUrl = 'https://www.instagram.com/murakami.flower2022';
export const privacyUrl = `${marketplaceUrl}/privacy`;
export const termsUrl = `${marketplaceUrl}/terms`;
export const collectorTermsUrl = `${marketplaceUrl}/collector-terms`;
export const twitterUrl = 'https://twitter.com/MFTMKKUS';

export const cloudinaryAvatarPrefix =
  'https://res.cloudinary.com/niftykit/image/fetch/c_scale,w_100/f_auto/';

export const ethUsdBuyAPI = 'https://api.coinbase.com/v2/prices/ETH-USD/buy';

export const cardsImagesPath =
  'https://res.cloudinary.com/niftykit/image/fetch/c_scale,w_500/q_100/https://storage.niftykit.com/ipfs/QmWpxVj6ge5MwckunBBaBtNqZW5JPFJwnLUjLcwa927GGJ';

export type CollectionId =
  | 'seeds'
  | 'flowers'
  | 'luckycat'
  | 'geisai2022'
  | 'flowercoins'
  | 'luckycatgame'
  | 'luckycatgamewinners'
  | 'geisai2022complete'
  | 'geisai22classic'
  | 'flowerjetcoin'
  | 'flowerpack'
  | 'flowercard';

export type MKCollection = {
  address: Hex;
  name: string;
  marketplaceUrl: string;
  id: CollectionId;
};

export const collections: MKCollection[] = [
  {
    address: '0x341a1c534248966c4b6afad165b98daed4b964ef',
    name: 'Murakami.Flowers Seed',
    id: 'seeds',
    marketplaceUrl: `${marketplaceUrl}/ethereum/collection/0x341a1c534248966c4b6afad165b98daed4b964ef`,
  },
  {
    address: '0x7d8820fa92eb1584636f4f5b8515b5476b75171a',
    name: 'Murakami.Flowers Official',
    id: 'flowers',
    marketplaceUrl: `${marketplaceUrl}/ethereum/collection/0x7d8820fa92eb1584636f4f5b8515b5476b75171a`,
  },
  {
    address: '0xdedf88899d7c9025f19c6c9f188deb98d49cd760',
    name: 'Murakami Lucky Cat Coin Bank',
    id: 'luckycat',
    marketplaceUrl: `${marketplaceUrl}/ethereum/collection/0xdedf88899d7c9025f19c6c9f188deb98d49cd760`,
  },
  {
    address: '0x015ac56d0cfb7adbac3ffbfabf67768f2f0996f3',
    name: 'GEISAI 2022 Official NFT',
    id: 'geisai2022',
    marketplaceUrl: `${marketplaceUrl}/ethereum/collection/0x015ac56d0cfb7adbac3ffbfabf67768f2f0996f3`,
  },
  {
    address: '0x3e84607606b25f8a3c75b6db4a8a6683d1e40284',
    name: 'Murakami.Flowers Coin',
    id: 'flowercoins',
    marketplaceUrl: `${marketplaceUrl}/ethereum/collection/0x3e84607606b25f8a3c75b6db4a8a6683d1e40284`,
  },
  {
    address: '0x592b96241367bab3b2bf930c9d41bdf801749878',
    name: 'Murakami Lucky Cat Coin Bank Feeding Game',
    id: 'luckycatgame',
    marketplaceUrl: `${marketplaceUrl}/ethereum/collection/0x592b96241367bab3b2bf930c9d41bdf801749878`,
  },
  {
    address: '0x963045ab5a193956641c84a320b89264ec1f324b',
    name: 'Murakami Lucky Cat Coin Bank Feeding Game Winners',
    id: 'luckycatgamewinners',
    marketplaceUrl: `${marketplaceUrl}/ethereum/collection/0x963045ab5a193956641c84a320b89264ec1f324b`,
  },
  {
    address: '0xd24a78a7c8088692c209e92737756b94c2bc1091',
    name: 'GEISAI 2022 Complete Special NFT',
    id: 'geisai2022complete',
    marketplaceUrl: `${marketplaceUrl}/ethereum/collection/0xd24a78a7c8088692c209e92737756b94c2bc1091`,
  },
  {
    address: '0x22673e5d3363f25b04e264f9bba705eef6b1ba66',
    name: 'GEISAI #22 & Classic Official NFT',
    id: 'geisai22classic',
    marketplaceUrl: `${marketplaceUrl}/ethereum/collection/0x22673e5d3363f25b04e264f9bba705eef6b1ba66`,
  },
  {
    address: '0xb3fb635b3e8cdbfd950ca6be1f16247675ca3857',
    name: 'Flower Jet Coin',
    id: 'flowerjetcoin',
    marketplaceUrl: `${marketplaceUrl}/ethereum/collection/0xb3fb635b3e8cdbfd950ca6be1f16247675ca3857`,
  },
  {
    address: packContractAddress,
    name: '108 Flowers NFT Packs',
    id: 'flowerpack',
    marketplaceUrl: `${marketplaceUrl}/base/collection/${packContractAddress}`,
  },
  {
    address: cardContractAddress,
    name: '108 Flowers NFT',
    id: 'flowercard',
    marketplaceUrl: `${marketplaceUrl}/base/collection/${cardContractAddress}`,
  },
];
