import { MAINNET_RELAY_API, createClient } from '@reservoir0x/relay-sdk';
import { createPublicClient, http } from 'viem';
import { base, mainnet } from 'viem/chains';
import { createConfig } from 'wagmi';

const baseAlchemyRpcUrl =
  'https://base-mainnet.g.alchemy.com/v2/Sf8OYRNKGHboUf3vsBPz3a9IFl8ksalj';

const mainnetAlchemyRpcUrl =
  'https://eth-mainnet.g.alchemy.com/v2/OZWjQ1TJ1MXFyYcSRpo-6pir6WyPr3MW';

export const basePublicClient = createPublicClient({
  chain: base,
  batch: {
    multicall: true,
  },
  transport: http(baseAlchemyRpcUrl, {
    batch: true,
  }),
});

export const mainnetPublicClient = createPublicClient({
  chain: mainnet,
  batch: {
    multicall: true,
  },
  transport: http(mainnetAlchemyRpcUrl, {
    batch: true,
  }),
});

export const wagmiConfig = createConfig({
  chains: [base, mainnet],
  ssr: true,
  batch: {
    multicall: true,
  },
  multiInjectedProviderDiscovery: false,
  transports: {
    [base.id]: http(baseAlchemyRpcUrl, {
      batch: true,
    }),
    [mainnet.id]: http(mainnetAlchemyRpcUrl, {
      batch: true,
    }),
  },
});

export const relayClient = createClient({
  baseApiUrl: MAINNET_RELAY_API,
});
