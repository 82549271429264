import { isZeroDevConnector } from '@dynamic-labs/ethereum-aa';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import { KernelSmartAccount } from '@zerodev/sdk';
import React from 'react';
import {
  Hex,
  PublicClient,
  WalletClient,
  encodeFunctionData,
  getContract,
  zeroAddress,
} from 'viem';
import { base, mainnet } from 'viem/chains';
import { walletActionsEip5792 } from 'viem/experimental';
import { useAccount, useWalletClient } from 'wagmi';
import { useWriteContracts } from 'wagmi/experimental';
import { useBalance } from 'wagmi';
import { useQuery } from '@tanstack/react-query';
import { createKernelClient } from '@/utils/zeroDev';
import { basePublicClient, relayClient } from '@/utils/wagmi';
import { transferBatchAbi } from '@/utils/abis/transferBatchAbi';
import { packAbi } from '@/utils/abis/packAbi';
import { RevealedPack } from '@/types/packs';
import {
  cardContractAddress,
  packContractAddress,
  packStartBlock,
  paymasterUrl,
} from '@/constants';

const shufflePack = (packTokenId: bigint, cardIds: bigint[]): RevealedPack => {
  let seed = Number(packTokenId % 10000n);
  const shuffledCardIds = cardIds;

  for (let i = 0; i < shuffledCardIds.length; i++) {
    seed = (seed * 1103515245 + 12345) & 0x7fffffff;
    const j = (i + seed) % shuffledCardIds.length;
    [shuffledCardIds[i], shuffledCardIds[j]] = [
      shuffledCardIds[j],
      shuffledCardIds[i],
    ];
  }

  return {
    tokenId: packTokenId,
    cardIds: shuffledCardIds,
  };
};

const getPackContract = (
  publicClient: PublicClient,
  walletClient: WalletClient
) =>
  getContract({
    address: packContractAddress as Hex,
    abi: packAbi,
    client: { public: publicClient, wallet: walletClient },
  });

export default function usePackContract() {
  const account = useAccount();
  const { data: walletClient } = useWalletClient({
    account: account?.address,
  });
  const { primaryWallet } = useDynamicContext();
  const { writeContracts } = useWriteContracts();

  const { data: baseBalance } = useBalance({
    chainId: base.id,
    address: account?.address,
  });
  const { data: mainnetBalance } = useBalance({
    chainId: mainnet.id,
    address: account?.address,
  });

  const {
    data: contract,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['contract', walletClient?.account?.address],
    queryFn: () => {
      if (!basePublicClient) return null;
      return getPackContract(
        basePublicClient as PublicClient,
        (walletClient ?? basePublicClient) as WalletClient
      );
    },
    enabled: !!basePublicClient,
  });

  const { data: saleValues } = useQuery({
    queryKey: ['saleValues'],
    queryFn: async () => {
      if (!contract) return null;

      const [
        basePrice,
        baseFee,
        saleDuration,
        saleStartTime,
        totalSupply,
        maxPerMint,
        maxPerReveal,
        totalMinted,
        saleActive,
      ] = await Promise.all([
        contract.read.basePrice(),
        contract.read.mintFee([1n]),
        contract.read.saleDuration(),
        contract.read.saleStartTime(),
        contract.read.totalSupply(),
        contract.read.maxPerMint(),
        contract.read.maxPerReveal(),
        contract.read.totalMinted(),
        contract.read.saleActive(),
      ]);

      return {
        basePrice: Number(basePrice),
        baseFee: Number(baseFee),
        saleDuration: Number(saleDuration),
        saleStartTime: Number(saleStartTime),
        totalSupply: Number(totalSupply),
        maxPerMint: Number(maxPerMint),
        maxPerReveal: Number(maxPerReveal),
        totalMinted: Number(totalMinted),
        saleActive,
      };
    },
    enabled: !!contract,
    refetchInterval: 10000,
  });

  const getCapabilities = React.useCallback(async () => {
    if (!walletClient) return {};
    try {
      const client = walletClient.extend(walletActionsEip5792());
      const availableCapabilities = await client.getCapabilities({
        account: walletClient.account,
      });
      const capabilitiesForChain = availableCapabilities[base.id];
      if (
        capabilitiesForChain['paymasterService'] &&
        capabilitiesForChain['paymasterService'].supported
      ) {
        return {
          paymasterService: {
            url: paymasterUrl,
          },
        };
      }
    } catch (e) {
      console.error(e);
    }
    return {};
  }, [walletClient]);

  const hasEnoughBaseBalance = React.useCallback(
    (amount: bigint) => {
      return (baseBalance?.value ?? 0n) >= amount;
    },
    [baseBalance]
  );

  const hasEnoughMainnetBalance = React.useCallback(
    (amount: bigint) => {
      return (mainnetBalance?.value ?? 0n) >= amount;
    },
    [mainnetBalance]
  );

  const hasEnoughBalance = React.useCallback(
    (amount: bigint) => {
      return hasEnoughBaseBalance(amount) || hasEnoughMainnetBalance(amount);
    },
    [hasEnoughBaseBalance, hasEnoughMainnetBalance]
  );

  const getMintFee = React.useCallback(
    async (quantity: number) => {
      if (!contract) return;
      return contract.read.mintFee([BigInt(quantity)]);
    },
    [contract]
  );

  const getTotalPrice = React.useCallback(
    async (quantity: number) => {
      if (!contract) return;
      return contract.read.price([BigInt(quantity)]);
    },
    [contract]
  );

  const isAwaitingReveal = React.useCallback(
    async (owner: Hex) => {
      if (!contract) return false;
      return contract.read.isAwaitingReveal([owner]);
    },
    [contract]
  );

  const getTokensOfOwner = React.useCallback(
    async (owner: Hex) => {
      if (!contract) return [];
      return contract.read.tokensOfOwner([owner]);
    },
    [contract]
  );

  const getDiscountBps = React.useCallback(
    async (quantity: number) => {
      if (!contract) return 0;
      return contract.read.discountBps([BigInt(quantity)]);
    },
    [contract]
  );

  const getRevealedPacks = React.useCallback(
    async (owner: Hex, tokenIds: bigint[]) => {
      if (!contract) return [];

      const burnLogs = await contract.getEvents.Transfer(
        {
          from: owner,
          to: zeroAddress,
          tokenId: tokenIds,
        },
        {
          strict: true,
          fromBlock: BigInt(packStartBlock),
        }
      );

      if (burnLogs.length === 0) {
        return [];
      }

      // fetch the first and last burn block number
      const sortedBurnLogs = burnLogs.sort((a, b) =>
        Number(a.blockNumber - b.blockNumber)
      );

      const firstBurnBlock = sortedBurnLogs[0].blockNumber;
      const lastBurnBlock =
        sortedBurnLogs[sortedBurnLogs.length - 1].blockNumber;

      const mintLogs = await basePublicClient.getContractEvents({
        address: cardContractAddress as Hex,
        abi: transferBatchAbi,
        eventName: 'TransferBatch',
        args: {
          from: zeroAddress,
          to: owner,
        },
        strict: true,
        fromBlock: BigInt(firstBurnBlock),
        toBlock: BigInt(lastBurnBlock),
      });

      // Sort logs by block number and transaction index
      const sortedLogs = [...burnLogs, ...mintLogs].sort((a, b) => {
        if (a.blockNumber === b.blockNumber) {
          return a.transactionIndex - b.transactionIndex;
        }
        return Number(a.blockNumber - b.blockNumber);
      });

      const newRevealedPacks: RevealedPack[] = [];
      let currentBurnedPacks: bigint[] = [];

      for (const log of sortedLogs) {
        if (log.eventName === 'Transfer' && log.args.to === zeroAddress) {
          // This is a burn event (pack revealed)
          currentBurnedPacks.push(log.args.tokenId ?? 0n);
        } else if (
          log.eventName === 'TransferBatch' &&
          log.args.from === zeroAddress
        ) {
          // This is a mint event (cards received)
          if (currentBurnedPacks.length > 0) {
            const cardIds = log.args.ids;
            for (let i = 0; i < currentBurnedPacks.length; i++) {
              newRevealedPacks.push(
                shufflePack(
                  currentBurnedPacks[i],
                  cardIds.slice(i * 5, (i + 1) * 5)
                )
              );
            }
            currentBurnedPacks = [];
          }
        }
      }

      return newRevealedPacks;
    },
    [contract]
  );

  const baseBatchReveal = React.useCallback(
    async (address: Hex, tokenIds: bigint[]) => {
      if (!contract) return;
      await contract.simulate.batchReveal([tokenIds], {
        chain: base,
        account: address as Hex,
      });

      const gas = await contract.estimateGas.batchReveal([tokenIds], {
        account: address as Hex,
      });

      const hash = await contract.write.batchReveal([tokenIds], {
        chain: base,
        account: address as Hex,
        gas: gas + (gas * 33n) / 100n,
      });

      await basePublicClient.waitForTransactionReceipt({ hash });
      return hash;
    },
    [contract]
  );

  const zeroDevBatchReveal = React.useCallback(
    async (tokenIds: bigint[]) => {
      // zero dev connector
      // dev: see https://docs.dynamic.xyz/account-abstraction/aa-providers/zerodev#using-zerodev-kernelaccountclient
      if (primaryWallet && isZeroDevConnector(primaryWallet.connector)) {
        const kernelClient =
          primaryWallet.connector.getAccountAbstractionProvider();
        if (kernelClient?.account) {
          const client = await createKernelClient(
            kernelClient.account as unknown as KernelSmartAccount<never>
          );

          const hash = await client.sendTransaction({
            to: packContractAddress as Hex,
            value: BigInt(0),
            data: encodeFunctionData({
              abi: packAbi,
              functionName: 'batchReveal',
              args: [tokenIds],
            }),
          });

          await basePublicClient.waitForTransactionReceipt({ hash });
          return hash;
        }
      }

      return null;
    },
    [primaryWallet]
  );

  const coinbaseBatchReveal = React.useCallback(
    async (address: Hex, tokenIds: bigint[]) => {
      // use paymaster service, for smart accounts
      // dev: see https://www.smartwallet.dev/guides/paymasters
      return new Promise((resolve, reject) => {
        writeContracts(
          {
            contracts: [
              {
                address: packContractAddress as Hex,
                abi: packAbi,
                functionName: 'batchReveal',
                args: [tokenIds],
              },
            ],
            capabilities: {
              paymasterService: {
                url: paymasterUrl,
              },
            },
            account: address,
          },
          {
            onSuccess: async (id) => {
              const client = walletClient?.extend(walletActionsEip5792());
              let status = await client?.getCallsStatus({ id });
              if (!status) {
                reject('Something went wrong. Please try again later.');
              }
              while (status?.status === 'PENDING') {
                // sleep for 100 ms
                await new Promise((r) => setTimeout(r, 100));
                status = await client?.getCallsStatus({ id });
                if (status?.receipts?.[0]?.status === 'reverted') {
                  reject('Transaction reverted');
                }
              }
              resolve(id);
            },
            onError: (error) => reject(error),
          }
        );
      });
    },
    [walletClient, writeContracts]
  );

  const crossChainMint = React.useCallback(
    async (address: Hex, quantity: bigint, totalPrice: bigint) => {
      if (!contract) return;
      if (!walletClient) return;

      const quote = await relayClient.actions.getQuote({
        chainId: mainnet.id,
        toChainId: base.id,
        txs: [
          {
            to: packContractAddress as Hex,
            value: totalPrice?.toString() ?? '0',
            data: encodeFunctionData({
              abi: packAbi,
              functionName: 'mint',
              args: [address, quantity],
            }),
          },
        ],
        wallet: walletClient,
        currency: '0x0000000000000000000000000000000000000000',
        toCurrency: '0x0000000000000000000000000000000000000000',
        tradeType: 'EXACT_OUTPUT',
        amount: totalPrice?.toString() ?? '0',
      });

      const mintPromise = new Promise<string>((resolve, reject) => {
        let txHash: string | undefined;

        relayClient.actions
          .execute({
            quote,
            wallet: walletClient,
            onProgress: (data) => {
              // Check if there's a transaction hash
              if (data.txHashes && data.txHashes.length > 0) {
                txHash = data.txHashes[data.txHashes.length - 1].txHash;
              }

              // Check for errors
              if (data.steps.some((step) => step.error)) {
                const errorStep = data.steps.find((step) => step.error);
                reject(
                  new Error(
                    errorStep?.error ||
                      'An error occurred during cross-chain minting'
                  )
                );
              }

              // Check if all steps are complete
              const allStepsComplete = data.steps.every((step) =>
                step.items?.every((item) => item.status === 'complete')
              );

              if (allStepsComplete) {
                if (txHash) {
                  resolve(txHash);
                } else {
                  reject(
                    new Error('Transaction completed but no hash was found')
                  );
                }
              }
            },
          })
          .catch((error) => {
            reject(
              new Error(
                `Failed to execute cross-chain minting: ${error.message}`
              )
            );
          });
      });

      const hash = (await mintPromise) as Hex;
      await basePublicClient.waitForTransactionReceipt({ hash });
      return hash;
    },
    [contract, walletClient]
  );

  const baseMint = React.useCallback(
    async (address: Hex, quantity: bigint, totalPrice: bigint) => {
      if (!contract) return;

      await contract.simulate.mint([address, quantity], {
        chain: base,
        account: address,
        value: totalPrice,
      });

      const gas = await contract.estimateGas.mint([address, quantity], {
        account: address,
        value: totalPrice,
      });

      const hash = await contract.write.mint([address, quantity], {
        chain: base,
        account: address,
        gas: gas + (gas * 33n) / 100n,
        value: totalPrice,
      });

      await basePublicClient.waitForTransactionReceipt({ hash });
      return hash;
    },
    [contract]
  );

  return {
    contract,
    isLoading,
    saleActive: saleValues?.saleActive,
    maxPerMint: saleValues?.maxPerMint,
    maxPerReveal: saleValues?.maxPerReveal,
    totalSupply: saleValues?.totalSupply,
    totalMinted: saleValues?.totalMinted,
    saleStartTime: saleValues?.saleStartTime,
    saleDuration: saleValues?.saleDuration,
    basePrice: saleValues?.basePrice,
    baseFee: saleValues?.baseFee,
    getCapabilities,
    getMintFee,
    getTotalPrice,
    getTokensOfOwner,
    getRevealedPacks,
    getDiscountBps,
    isAwaitingReveal,
    hasEnoughBalance,
    hasEnoughBaseBalance,
    hasEnoughMainnetBalance,
    baseBatchReveal,
    coinbaseBatchReveal,
    zeroDevBatchReveal,
    baseMint,
    crossChainMint,
    refetch,
  };
}
