import { useCrossmintEvents } from '@crossmint/client-sdk-react-ui';
import React from 'react';
import {
  PurchaseStatus,
  TransactionFulfillmentSucceededPayload,
} from '@/types/crossmint';

type Props = {
  orderIdentifier: string;
  onFail: () => void;
  onSuccess: (result: TransactionFulfillmentSucceededPayload | null) => void;
};

const failedEvents = [
  'payment:preparation.failed',
  'payment:process.canceled',
  'payment:process.rejected',
  'transaction:fulfillment.failed',
  'quote:status.invalidated',
  'payments:mint-config.invalid',
  'payments:payment-method.invalid',
  'payments:email.invalid',
  'payments:client-id.invalid',
  'payments:minting-contract.missing',
  'payments:collection.disabled',
  'payments:collection.unavailable',
  'payments:collection.unverified',
  'payments:project.unverified',
  'payments:collection.sold-out',
  'payments:collection.not-live',
  'payments:collection.sale-ended',
  'payments:user-wallet.limit-reached',
  'payments:user-wallet.not-whitelisted',
  'payments:user-wallet.invalid',
  'payments:payment-rejected.generic-decline',
  'payments:payment-rejected.insufficient-funds',
  'payments:payment-rejected.card-lost',
  'payments:payment-rejected.card-stolen',
  'payments:payment-rejected.card-expired',
  'payments:payment-rejected.card-incorrect-cvc',
  'payments:payment-rejected.processing-error',
  'payments:payment-rejected.card-incorrect-number',
  'payments:transaction-error.generic',
  'payments:contract-execution-reverted.generic',
  'payments:embedded-checkout.not-enabled',
];

const successEvents = ['transaction:fulfillment.succeeded'];

const CrossmintStatus: React.FC<Props> = ({
  orderIdentifier,
  onFail,
  onSuccess,
}) => {
  const [status, setStatus] = React.useState<PurchaseStatus>('pending');
  const { listenToMintingEvents } = useCrossmintEvents();

  if (status === 'pending') {
    listenToMintingEvents({ orderIdentifier }, (event) => {
      if (successEvents.includes(event.type)) {
        setStatus('success');
        onSuccess(event.payload as TransactionFulfillmentSucceededPayload);
      } else if (failedEvents.includes(event.type)) {
        setStatus('failure');
        onFail();
      }
    });
  }

  return null;
};

export default CrossmintStatus;
