import * as Tabs from '@radix-ui/react-tabs';
import { AnimatePresence, motion } from 'framer-motion';
import numeral from 'numeral';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Hex } from 'viem';
import Card from './Card';
import DisplayCard from './DisplayCard';
import CardPack from './CardPack';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import VirtualizedGrid from '@/components/common/VirtualizedGrid';
import { Button, Flex, Text } from '@/components/primitives';
import {
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/primitives/Tab';
import { cardsImagesPath, marketplaceUrl } from '@/constants';
import { useSoundContext } from '@/context/SoundContextProvider';
import { useMounted } from '@/hooks';
import { useCards } from '@/hooks/useCards';
import { RevealedPack } from '@/types/packs';
import { getPackLanguage } from '@/utils/packs';
import { Card as CardType } from '@/types/cards';

type Props = {
  address?: string;
  allRevealedPacks: RevealedPack[];
  isLoading: boolean;
  packTokenIds: bigint[];
  revealedPacks: RevealedPack[];
  revealAllCards: boolean;
  onReveal: (tokenId: bigint) => void;
  handleShowAllCards: () => void;
  hidePrevRevealedCards: boolean;
  packsToOpen: string;
  toggleShowInfoFooter: (s: string) => void;
  unhiddenRevealedCards: bigint[];
};

const CardPackContainer: FC<Props> = ({
  address,
  allRevealedPacks,
  isLoading,
  packTokenIds,
  revealedPacks,
  revealAllCards,
  onReveal,
  handleShowAllCards,
  hidePrevRevealedCards,
  packsToOpen,
  toggleShowInfoFooter,
  unhiddenRevealedCards,
}) => {
  const cardVariants = {
    initial: {
      opacity: 0,
      y: 50,
    },
    animate: () => ({
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.25,
      },
    }),
  };

  const { cards } = useCards(address as Hex);
  const totalCards = cards?.reduce(
    (acc, card) => acc + Number(card.quantity),
    0
  );

  const isMounted = useMounted();
  const { playPackOpen } = useSoundContext();
  const isMobile = useMediaQuery({ maxWidth: 767 }) && isMounted;
  const revealedTokenIds = useMemo(() => {
    return revealedPacks.map((pack) => pack.tokenId);
  }, [revealedPacks]);

  const [revealCardPack, setRevealCardPack] = useState(false);
  const [hideRevealedCardPack, setHideRevealedCardPack] = useState(false);
  const [tabValue, setTabValue] = useState('packs');
  const openCardsList = useMemo(() => new Set<string>(), []);
  const currentlyRevealedCards: bigint[] = [];

  useEffect(() => {
    const revealCardPackTimer = setTimeout(() => {
      if (unhiddenRevealedCards.length > 0 && hidePrevRevealedCards) {
        setRevealCardPack(true);
        playPackOpen();
        clearTimeout(revealCardPackTimer);
      }
    }, 3000);
    const hideRevealedCardPackTimer = setTimeout(() => {
      if (unhiddenRevealedCards.length > 0 && hidePrevRevealedCards) {
        setHideRevealedCardPack(true);
        clearTimeout(hideRevealedCardPackTimer);
      }
    }, 4000);

    if (!unhiddenRevealedCards.length) {
      setRevealCardPack(false);
      setHideRevealedCardPack(false);
      openCardsList.clear();
    }

    return () => {
      setHideRevealedCardPack(false);
      clearTimeout(revealCardPackTimer);
      clearTimeout(hideRevealedCardPackTimer);
    };
  }, [
    hidePrevRevealedCards,
    openCardsList,
    unhiddenRevealedCards.length,
    playPackOpen,
  ]);

  const checkOpenCardsLengthMatch = useCallback(
    (id: string, view: boolean) => {
      if (view) {
        openCardsList.add(id);
      } else {
        openCardsList.delete(id);
      }
      if (revealedPacks.length * 5 === Array.from(openCardsList).length) {
        handleShowAllCards();
      }
    },
    [handleShowAllCards, openCardsList, revealedPacks.length]
  );

  return (
    <Flex
      css={{
        maxWidth: 1560,
        width: '100%',
        overflowY: 'auto',
        height: '100%',
        position: 'relative',
      }}
      className="card-pack-container">
      <Flex
        css={{
          gap: '$4 $5',
          padding: isMobile ? '$2' : '$2 $6',
          alignItems: 'center',
          overflow: 'hidden',
          justifyContent: 'flex-start',
          pointerEvents: 'auto',
          width: '100%',
          flexWrap: 'wrap',
          paddingBottom: '250px',
        }}>
        <Tabs.Root
          value={tabValue}
          onValueChange={(value) => {
            setTabValue(value);
            toggleShowInfoFooter(value);
          }}
          style={{
            width: '100%',
            paddingRight: isMobile ? 0 : 15,
          }}>
          {!isLoading && !hidePrevRevealedCards && (
            <TabsList css={{ marginTop: isMobile ? 6 : '$5', color: 'black' }}>
              <TabsTrigger
                css={{ borderRadius: 0, background: 'transparent' }}
                value="packs">
                Card Packs ({numeral(packTokenIds.length).format('0,0')})
              </TabsTrigger>
              <TabsTrigger
                css={{ borderRadius: 0, background: 'transparent' }}
                value="cards">
                Cards ({numeral(totalCards).format('0,0')})
              </TabsTrigger>
            </TabsList>
          )}

          <TabsContent
            value="packs"
            css={{ paddingBottom: isMobile ? '124px' : '60px' }}>
            {isLoading ? (
              <Flex justify="center" align="center" css={{ height: 650 }}>
                <LoadingSpinner />
              </Flex>
            ) : (
              <>
                {!packTokenIds.length ? (
                  <AnimatePresence mode="popLayout">
                    <Flex
                      direction="column"
                      css={{
                        padding: isMobile ? '$6 $1' : '$6',
                        gap: '$4 $5',
                        alignItems: 'center',
                        justifyContent: 'center',
                        pointerEvents: 'auto',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                      }}>
                      <Text
                        css={{
                          marginTop: isMobile ? 8 : 12,
                          marginBottom: 6,
                          fontFamily: 'Poppins-Bold',
                          fontSize: 21,
                          fontWeight: 700,
                          textAlign: 'center',
                          lineHeight: '1em',
                          '@md': { fontSize: 32, lineHeight: '1.3em' },
                        }}>
                        {!allRevealedPacks.length ? (
                          <>You have no cards or packs.</>
                        ) : (
                          <>You&apos;ve opened all your packs.</>
                        )}
                      </Text>
                      <a href={`${marketplaceUrl}/profile/${address}`}>
                        <Button
                          color="primary"
                          css={{
                            width: isMobile ? 300 : 444,
                            justifyContent: 'center',
                          }}>
                          View Profile
                        </Button>
                      </a>
                    </Flex>
                  </AnimatePresence>
                ) : (
                  <>
                    <Flex
                      direction="row"
                      className={
                        revealCardPack ? 'hide-card-pack-container' : ''
                      }
                      css={{
                        minHeight:
                          revealCardPack || !hidePrevRevealedCards ? 0 : 700,
                        paddingTop: !hidePrevRevealedCards
                          ? 0
                          : isMobile
                          ? 0
                          : 64,
                        justifyContent: 'center',
                        gap: '$5',
                        flexWrap: 'wrap',
                        transition: 'min-height 1s ease-in-out',
                      }}>
                      {packTokenIds.map((packTokenId, index) => {
                        const packCards =
                          revealedPacks.find(
                            (pack) => pack.tokenId === packTokenId
                          )?.cardIds ?? [];
                        currentlyRevealedCards.push(...packCards);
                        const language = getPackLanguage(packTokenId);
                        return (
                          <React.Fragment key={index}>
                            {unhiddenRevealedCards.includes(packTokenId) &&
                              revealedTokenIds.includes(packTokenId) &&
                              revealCardPack && (
                                <CardPack
                                  dataId={`${packTokenId}`}
                                  layoutId={`card-pack-${Number(
                                    packTokenId
                                  )}-${index}`}
                                  revealing={revealCardPack}
                                  className={`
                          ${hideRevealedCardPack ? 'hidden-card ' : ''}
                          ${packsToOpen === 'one' ? 'hidden-single-card' : ''}
                        `}
                                  hidePrevRevealedCards={hidePrevRevealedCards}
                                  language={language}
                                />
                              )}
                          </React.Fragment>
                        );
                      })}
                    </Flex>

                    {!hidePrevRevealedCards && (
                      <VirtualizedGrid
                        data={packTokenIds}
                        listClassName={
                          'virtuoso-grid-list virtuoso-grid-list-pack'
                        }
                        itemContent={(index: number, packTokenId: bigint) => {
                          if (!packTokenId) return null;
                          const language = getPackLanguage(packTokenId);
                          return (
                            <React.Fragment key={index}>
                              <CardPack
                                layoutId={`card-pack-${Number(
                                  packTokenId
                                )}-${index}`}
                                // custom={index}
                                revealing={revealedTokenIds.includes(
                                  packTokenId
                                )}
                                onClick={() => {
                                  onReveal(packTokenId);
                                }}
                                className={
                                  revealedTokenIds.includes(packTokenId)
                                    ? 'hidden-card'
                                    : ''
                                }
                                language={language}
                              />
                            </React.Fragment>
                          );
                        }}
                      />
                    )}
                  </>
                )}

                {revealCardPack && currentlyRevealedCards.length > 0 && (
                  <VirtualizedGrid
                    data={currentlyRevealedCards}
                    initialCount={25}
                    listClassName={`virtuoso-grid-list virtuoso-grid-list-hidden ${
                      hideRevealedCardPack && 'virtuoso-grid-list-animation'
                    }`}
                    itemContent={(_index: number, cardTokenId: bigint) => {
                      if (!cardTokenId) return null;

                      return (
                        <motion.div
                          key={`card-${Number(cardTokenId)}-${_index}`}
                          variants={cardVariants}>
                          <Card
                            initialView={revealAllCards} // To show the card by default, set this to true and disable custom prop
                            //custom={undefined} // To enable auto reveal, pass a custom prop to the Card component
                            cardName={`Card ${cardTokenId}`}
                            imageUrl={`${cardsImagesPath}/${cardTokenId}.png`}
                            onFlipCard={(id: string, view: boolean) => {
                              checkOpenCardsLengthMatch(id, view);
                            }}
                            id={`${Number(cardTokenId)}-${_index}`}
                            cardTokenId={Number(cardTokenId)}
                          />
                        </motion.div>
                      );
                    }}
                  />
                )}
              </>
            )}
          </TabsContent>

          <TabsContent value="cards">
            <VirtualizedGrid
              data={cards || []}
              initialCount={25}
              itemContent={(_index: number, card: CardType) => {
                if (!card) return null;

                return (
                  <motion.div
                    key={`card-${Number(card.tokenId)}-${Number(
                      card.tokenId
                    )}-${_index}`}
                    variants={cardVariants}>
                    <DisplayCard
                      initialView={true} // To show the card by default, set this to true and disable custom prop
                      //custom={_index} // To enable auto reveal, pass a custom prop to the Card component
                      revealedAtStart={true}
                      quantity={Number(card.quantity)}
                      cardName={`Card ${card.tokenId}`}
                      imageUrl={`${cardsImagesPath}/${card.tokenId}.png`}
                      onFlipCard={(id: string, view: boolean) => {
                        checkOpenCardsLengthMatch(id, view);
                      }}
                      id={`${Number(card.tokenId)}-${card.tokenId}-${_index}`}
                      cardTokenId={Number(card.tokenId)}
                    />
                  </motion.div>
                );
              }}
            />
          </TabsContent>
        </Tabs.Root>
      </Flex>
    </Flex>
  );
};

export default CardPackContainer;
