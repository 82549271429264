import { createKernelAccountClient, KernelSmartAccount } from '@zerodev/sdk';
import { createPimlicoPaymasterClient } from 'permissionless/clients/pimlico';
import { http } from 'viem';
import { base } from 'viem/chains';
import { coinbaseEntryPoint, paymasterUrl } from '@/constants';

export const createKernelClient = async (
  account: KernelSmartAccount<never>
) => {
  const transport = http(paymasterUrl);

  const cloudPaymaster = createPimlicoPaymasterClient({
    chain: base,
    transport,
  });

  const kernelClient = createKernelAccountClient({
    account,
    chain: base,
    transport,
    sponsorUserOperation: async ({ userOperation }) => {
      return cloudPaymaster.sponsorUserOperation({
        userOperation,
        entryPoint: coinbaseEntryPoint,
      });
    },
  });

  return kernelClient;
};
