import { isZeroDevConnector } from '@dynamic-labs/ethereum-aa';
import {
  useDynamicContext,
  useSwitchWallet,
  useUserWallets,
} from '@dynamic-labs/sdk-react-core';
import { EthereumRpcError } from 'eth-rpc-errors';
import { motion } from 'framer-motion';
import numeral from 'numeral';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Hex, parseEther } from 'viem';
import { base } from 'viem/chains';
import { useAccount, useWalletClient } from 'wagmi';
import CardPackContainer from '@/components/Drops/CardPackContainer';
import { OpenPackModal } from '@/components/Drops/OpenPackModal';
import { RaritySummary } from '@/components/Drops/RaritySummary';
import { Box, Button, Flex, Text } from '@/components/primitives';
import { marketplaceUrl } from '@/constants';
import { useSoundContext } from '@/context/SoundContextProvider';
import { ToastContext } from '@/context/ToastContextProvider';
import { useMounted, usePackContract } from '@/hooks';
import { RevealedPack } from '@/types/packs';
import { calculateRaritySummary } from '@/utils/cards';
import { handleError } from '@/utils/errors';

export const Packs = () => {
  const isMounted = useMounted();
  const isMobile = useMediaQuery({ maxWidth: 767 }) && isMounted;
  const {
    contract,
    isAwaitingReveal,
    getRevealedPacks,
    getTokensOfOwner,
    hasEnoughBaseBalance,
    maxPerReveal,
    baseBatchReveal,
    zeroDevBatchReveal,
    coinbaseBatchReveal,
    getCapabilities,
  } = usePackContract();
  const { addToast } = useContext(ToastContext);
  const { primaryWallet, isAuthenticated, setShowAuthFlow, walletConnector } =
    useDynamicContext();
  const userWallets = useUserWallets();
  const switchWallet = useSwitchWallet();
  const { isConnected, address: wagmiAddress, chain } = useAccount();
  const address =
    primaryWallet?.address ?? wagmiAddress ?? userWallets[0]?.address;
  const { data: wallet } = useWalletClient({
    chainId: base.id,
  });
  const [packTokenIds, setPackTokenIds] = useState<bigint[]>([]);
  const [revealedPacks, setRevealedPacks] = useState<RevealedPack[]>([]);
  const [allRevealedPacks, setAllRevealedPacks] = useState<RevealedPack[]>([]);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [packsToOpen, setPacksToOpen] = useState<'one' | 'many'>('one');
  const [hidePrevRevealedCards, setHidePrevRevealedCards] = useState(false);
  const [unhiddenRevealedCards, setUnhiddenRevealedCards] = useState<bigint[]>(
    []
  );
  const [showInfoFooter, setShowInfoFooter] = useState(true);

  const [revealAllCards, setRevealAllCards] = useState(false);
  const [revealRaritySummary, setRevealRaritySummary] = useState(false);
  const [isRevealing, setIsRevealing] = useState(false);
  const [isSmartWallet, setIsSmartWallet] = useState(false);
  const { playFlip } = useSoundContext();

  const getOwnerRevealedPacks = useCallback(async () => {
    if (address && contract) {
      const currentRevealedPacks = await getRevealedPacks(address as Hex, []);
      setAllRevealedPacks(currentRevealedPacks);
    }
  }, [address, contract, getRevealedPacks]);

  const updatePacks = useCallback(
    async (
      revealNewCards: boolean = false,
      justRevealedTokenIds: bigint[] = []
    ) => {
      if (address && contract) {
        const tokenIds = await getTokensOfOwner(address as Hex);
        setPackTokenIds([...tokenIds]);

        const currentRevealedPacks = await getRevealedPacks(address as Hex, [
          ...tokenIds,
        ]);
        revealNewCards && setAllRevealedPacks(currentRevealedPacks);
        if (justRevealedTokenIds.length > 0) {
          const packs = await getRevealedPacks(
            address as Hex,
            justRevealedTokenIds
          );
          setRevealedPacks([...packs]);

          if (revealNewCards && packs.length > 0) {
            setHidePrevRevealedCards(true);
            getOwnerRevealedPacks();
          }
          if (justRevealedTokenIds.length > 0 && packs.length > 0) {
            setUnhiddenRevealedCards(justRevealedTokenIds);
          }
        }
        setIsLoading(false);
      }
    },
    [
      address,
      contract,
      getOwnerRevealedPacks,
      getTokensOfOwner,
      getRevealedPacks,
    ]
  );

  const pollForRevealCompletion = useCallback(
    async (owner: Hex) => {
      const pollInterval = 2000; // 2 seconds

      let isAwaiting = await isAwaitingReveal(owner);
      while (isAwaiting) {
        await new Promise((resolve) => setTimeout(resolve, pollInterval));
        isAwaiting = await isAwaitingReveal(owner);
      }
    },
    [isAwaitingReveal]
  );

  useEffect(() => {
    updatePacks();
  }, [updatePacks]);

  useEffect(() => {
    getOwnerRevealedPacks();
  }, [getOwnerRevealedPacks]);

  useEffect(() => {
    let isMounted = true;

    const checkAndPollForReveal = async () => {
      if (!address || !contract || confirmationOpen || isLoading) return;

      const isAwaiting = await isAwaitingReveal(address as Hex);

      if (isAwaiting && isMounted) {
        setConfirmationOpen(true);
        setIsRevealing(true);
        await pollForRevealCompletion(address as Hex);
        if (isMounted) {
          setConfirmationOpen(false);
          setIsRevealing(false);
          updatePacks();
        }
      }
    };

    checkAndPollForReveal();

    return () => {
      isMounted = false;
    };
  }, [
    address,
    confirmationOpen,
    contract,
    isAwaitingReveal,
    isLoading,
    pollForRevealCompletion,
    updatePacks,
  ]);

  const raritySummary = useMemo(() => {
    if (revealedPacks.length > 0) {
      const packs = revealedPacks.filter((pack) =>
        unhiddenRevealedCards.includes(pack.tokenId)
      );
      const allCardIds = packs.flatMap((pack) => pack.cardIds);
      return calculateRaritySummary(allCardIds);
    }
  }, [revealedPacks, unhiddenRevealedCards]);

  const handleRevealSingle = useCallback(
    async (tokenId: bigint) => {
      if (!isConnected || !isAuthenticated || !wallet) {
        setShowAuthFlow(true);
        return;
      }

      if (isAuthenticated && !primaryWallet && userWallets.length >= 1) {
        switchWallet(userWallets[0].id);
        return;
      }

      try {
        setPacksToOpen('one');
        setConfirmationOpen(true);

        // always switch to base
        if (
          walletConnector &&
          walletConnector.supportsNetworkSwitching() &&
          chain?.id !== base.id
        ) {
          await walletConnector.switchNetwork({ networkChainId: base.id });
        }

        const capabilities = await getCapabilities();
        if (capabilities && capabilities.paymasterService) {
          // check coinbase
          setIsSmartWallet(true);
          await coinbaseBatchReveal(address as Hex, [tokenId]);
        } else if (
          // check zero dev
          primaryWallet &&
          isZeroDevConnector(primaryWallet.connector)
        ) {
          setIsSmartWallet(true);
          await zeroDevBatchReveal([tokenId]);
        } else {
          // normal wallet
          await baseBatchReveal(address as Hex, [tokenId]);
        }

        setIsRevealing(true);
        await pollForRevealCompletion(address as Hex);
        await updatePacks(true, [tokenId]);
      } catch (error) {
        addToast?.({
          title: 'Reveal Failure',
          description: handleError(error as EthereumRpcError<unknown>),
          status: 'error',
        });
      } finally {
        setConfirmationOpen(false);
        setIsRevealing(false);
        setIsSmartWallet(false);
      }
    },
    [
      addToast,
      address,
      baseBatchReveal,
      chain?.id,
      coinbaseBatchReveal,
      getCapabilities,
      isAuthenticated,
      isConnected,
      pollForRevealCompletion,
      primaryWallet,
      setShowAuthFlow,
      switchWallet,
      updatePacks,
      userWallets,
      wallet,
      walletConnector,
      zeroDevBatchReveal,
    ]
  );

  const handleRevealAll = useCallback(async () => {
    if (!isConnected || !isAuthenticated || !wallet) {
      setShowAuthFlow(true);
      return;
    }
    if (isAuthenticated && !primaryWallet && userWallets.length >= 1) {
      switchWallet(userWallets[0].id);
      return;
    }

    try {
      if (packTokenIds.length > 1) {
        setPacksToOpen('many');
      }
      const maxToOpen = Math.min(packTokenIds.length, maxPerReveal ?? 100);
      const packIds = packTokenIds.slice(0, maxToOpen);
      setConfirmationOpen(true);

      // always switch to base
      if (
        walletConnector &&
        walletConnector.supportsNetworkSwitching() &&
        chain?.id !== base.id
      ) {
        await walletConnector.switchNetwork({ networkChainId: base.id });
      }

      const capabilities = await getCapabilities();
      if (capabilities && capabilities.paymasterService) {
        // check coinbase
        setIsSmartWallet(true);
        await coinbaseBatchReveal(address as Hex, packIds);
      } else if (
        // check zero dev
        primaryWallet &&
        isZeroDevConnector(primaryWallet.connector)
      ) {
        setIsSmartWallet(true);
        await zeroDevBatchReveal(packIds);
      } else {
        // normal wallet
        await baseBatchReveal(address as Hex, packIds);
      }

      setIsRevealing(true);
      await pollForRevealCompletion(address as Hex);
      await updatePacks(true, packIds);
    } catch (error) {
      addToast?.({
        title: 'Reveal Failure',
        description: handleError(error as EthereumRpcError<unknown>),
        status: 'error',
      });
    } finally {
      setConfirmationOpen(false);
      setIsRevealing(false);
      setIsSmartWallet(false);
    }
  }, [
    addToast,
    address,
    baseBatchReveal,
    chain?.id,
    coinbaseBatchReveal,
    getCapabilities,
    isAuthenticated,
    isConnected,
    maxPerReveal,
    packTokenIds,
    pollForRevealCompletion,
    primaryWallet,
    setShowAuthFlow,
    switchWallet,
    updatePacks,
    userWallets,
    wallet,
    walletConnector,
    zeroDevBatchReveal,
  ]);

  const handleShowAllCards = () => {
    setRevealRaritySummary(true);
    setRevealAllCards(true);
    playFlip();
  };

  return (
    <>
      <Flex
        css={{
          width: '100%',
          justifyContent: 'center',
          position: 'relative',
        }}>
        {isAuthenticated ? (
          <CardPackContainer
            address={address}
            isLoading={isLoading}
            packTokenIds={Array.from(
              new Set([...packTokenIds, ...revealedPacks.map((p) => p.tokenId)])
            )}
            onReveal={handleRevealSingle}
            allRevealedPacks={allRevealedPacks}
            revealAllCards={revealAllCards}
            revealedPacks={revealedPacks}
            toggleShowInfoFooter={(e: string) =>
              setShowInfoFooter(e === 'packs')
            }
            handleShowAllCards={handleShowAllCards}
            hidePrevRevealedCards={hidePrevRevealedCards}
            packsToOpen={packsToOpen}
            unhiddenRevealedCards={unhiddenRevealedCards}
          />
        ) : (
          <Flex
            css={{
              gap: '$5',
              padding: '$6',
              alignItems: 'center',
              justifyContent: 'center',
              pointerEvents: 'auto',
              overflowY: 'auto',
              overflowX: 'hidden',
              flexDirection: 'column',
            }}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              style={{
                maxWidth: 260,
              }}
              src={'/images/108-flowers-pack.jpg'}
              alt="108 flowers trading cards"
            />
            <Text
              css={{
                fontFamily: 'Poppins-Bold',
                fontSize: 32,
                fontWeight: 700,
                textAlign: 'center',
                lineHeight: '48px',
              }}>
              Sign in to view your cards
            </Text>
            <Button
              css={{
                width: '100%',
                maxWidth: '444px',
                marginBottom: 24,
                justifyContent: 'center',
                textWrap: 'nowrap',
              }}
              onClick={() => setShowAuthFlow(true)}>
              Sign In
            </Button>
          </Flex>
        )}

        {showInfoFooter &&
          isAuthenticated &&
          (packTokenIds.length > 0 || hidePrevRevealedCards) && (
            <Flex
              direction="column"
              css={{
                position: 'fixed',
                bottom: 0,
                insetInline: 0,
                gap: '$4',
                padding: '24px $5',
                zIndex: 100,
                alignItems: 'center',
                justifyContent: 'flex-end',
                background: 'white',
                boxShadow: '0px -3px 20px 2px #0000000D',
                '@md': {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                },
              }}>
              {!hidePrevRevealedCards ? (
                <>
                  <Text
                    css={{
                      fontFamily: 'Poppins-Bold',
                      fontSize: 18,
                      fontWeight: 700,
                      textAlign: 'center',
                      lineHeight: '1em',
                      '@md': { fontSize: 20, lineHeight: '1.3em' },
                    }}>
                    Tap a Card Pack to open and reveal your cards.
                  </Text>

                  <Flex
                    direction={isMobile ? 'column' : 'row'}
                    css={{
                      gap: '$3',
                      justifyContent: 'center',
                    }}>
                    <a href={`${marketplaceUrl}/profile/${address}`}>
                      <Button
                        color="secondary"
                        css={{
                          width: isMobile ? '100%' : 238,
                          justifyContent: 'center',
                        }}>
                        View Profile
                      </Button>
                    </a>

                    <Button
                      color="primary"
                      onClick={handleRevealAll}
                      disabled={isRevealing}
                      css={{
                        width: isMobile ? '100%' : 260,
                        justifyContent: 'center',
                      }}>
                      Open{' '}
                      {packTokenIds.length < (maxPerReveal ?? 100)
                        ? `All (${numeral(packTokenIds.length ?? 0).format(
                            '0,0'
                          )})`
                        : `${Math.min(
                            packTokenIds.length,
                            maxPerReveal ?? 100
                          )}/
                        ${numeral(packTokenIds.length ?? 0).format('0,0')}`}
                    </Button>
                  </Flex>
                </>
              ) : (
                <>
                  {revealRaritySummary ? (
                    <>
                      <Box
                        css={{
                          textAlign: 'center',
                          '@md': {
                            textAlign: 'left',
                          },
                        }}>
                        <motion.div
                          transition={{ duration: 1.5, delay: 0.5 }}
                          initial={{
                            opacity: 0,
                          }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: isMobile ? 'center' : 'flex-start',
                          }}>
                          <Text
                            css={{
                              fontFamily: 'Poppins-Bold',
                              fontSize: 18,
                              fontWeight: 700,
                              textAlign: 'center',
                              lineHeight: '1.1em',
                              '@md': {
                                fontSize: 20,
                                lineHeight: '1.3em',
                                textAlign: 'left',
                              },
                            }}>
                            <RaritySummary
                              summary={
                                raritySummary ?? {
                                  C: 0,
                                  R: 0,
                                  SR: 0,
                                  UR: 0,
                                  SA: 0,
                                }
                              }
                            />
                          </Text>
                        </motion.div>
                        <Text
                          css={{
                            marginBottom: '$1',
                            lineHeight: '1em',
                            fontSize: '11px',
                            '@md': {
                              marginBottom: '$3',
                              fontSize: '16px',
                              lineHeight: '1.3em',
                            },
                          }}>
                          Visit your profile to view cards or list them for
                          sale.
                        </Text>
                      </Box>

                      <Flex
                        direction={isMobile ? 'column' : 'row'}
                        css={{
                          gap: '$3',
                          justifyContent: 'center',
                        }}>
                        {packTokenIds.length ? (
                          <>
                            <a href={`${marketplaceUrl}/profile/${address}`}>
                              <Button
                                color="secondary"
                                css={{
                                  width: isMobile ? '100%' : 238,
                                  justifyContent: 'center',
                                }}>
                                View Profile
                              </Button>
                            </a>

                            <Button
                              color="primary"
                              onClick={() => {
                                setHidePrevRevealedCards(false);
                                setUnhiddenRevealedCards([]);
                                setRevealAllCards(false);
                                setRevealedPacks([]);
                                setRevealRaritySummary(false);
                              }}
                              css={{
                                width: isMobile ? '100%' : 'auto',
                                minWidth: 270,
                                justifyContent: 'center',
                              }}>
                              Open Another Card Pack
                            </Button>
                          </>
                        ) : (
                          <>
                            <a href={`${marketplaceUrl}/profile/${address}`}>
                              <Button
                                color="primary"
                                css={{
                                  width: isMobile ? '100%' : 260,
                                  justifyContent: 'center',
                                }}>
                                View Profile
                              </Button>
                            </a>
                          </>
                        )}
                      </Flex>
                    </>
                  ) : (
                    <>
                      <Box
                        css={{
                          textAlign: 'center',
                          '@md': {
                            textAlign: 'left',
                          },
                        }}>
                        <Text
                          css={{
                            fontFamily: 'Poppins-Bold',
                            fontSize: 18,
                            fontWeight: 700,
                            textAlign: 'center',
                            lineHeight: '1em',
                            '@md': { fontSize: 20, lineHeight: '1.3em' },
                          }}>
                          Tap cards to flip and reveal
                        </Text>
                      </Box>
                      <Button
                        color="primary"
                        css={{
                          width: 260,
                          justifyContent: 'center',
                        }}
                        onClick={handleShowAllCards}>
                        Reveal All
                      </Button>
                    </>
                  )}
                </>
              )}
            </Flex>
          )}
      </Flex>
      <Box className="card-back"></Box>
      {confirmationOpen && (
        <OpenPackModal
          enoughBalance={hasEnoughBaseBalance(parseEther('0.0001'))}
          isSmartWallet={!!isSmartWallet}
          isRevealing={isRevealing}
          packsToOpen={packsToOpen}
        />
      )}
    </>
  );
};
