import clsx from 'clsx';
import { HTMLMotionProps, motion } from 'framer-motion';
import { FC, useRef } from 'react';

const CardPack: FC<
  HTMLMotionProps<'div'> & {
    language: 'en' | 'jp';
    className?: string;
    dataId?: string;
    revealing?: boolean;
    hidePrevRevealedCards?: boolean;
  }
> = ({
  language,
  className,
  dataId,
  hidePrevRevealedCards,
  revealing,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const packVariants = {
    initial: {
      opacity: 0,
      y: hidePrevRevealedCards ? 0 : 50,
    },
    animate: (i: number) => ({
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.25,
      },
      ease: 'easeOut',
    }),
    exit: {
      opacity: 0,
      y: 50,
      transition: {
        duration: 0.1,
      },
      ease: 'easeOut',
    },
  };

  return (
    <motion.div
      ref={ref}
      className={clsx(
        'card-pack',
        {
          'card-pack-cut': revealing,
        },
        className
      )}
      data-id={dataId}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={packVariants}
      {...props}>
      <div className={`card-top card-top-${language}`}></div>
      <div className={`card-bottom card-bottom-${language}`}></div>
    </motion.div>
  );
};

export default CardPack;
