import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';
import { Text } from '@/components/primitives';

type NavItemProps = {
  children: ReactNode;
  active?: boolean;
  submenu?: boolean;
};

const NavItem = forwardRef<
  HTMLParagraphElement,
  ComponentPropsWithoutRef<typeof Text> & NavItemProps
>(({ active, children, submenu, ...props }, forwardedRef) => (
  <Text
    {...props}
    ref={forwardedRef}
    css={{
      pb: 1,
      color: active ? '$gray10' : '$gray12',
      cursor: 'pointer',
      fontWeight: submenu ? 600 : 700,
      '&:hover': {
        color: '$gray11',
      },
      //borderBottom: active ? '2px solid #4b9fee' : '2px solid transparent',
    }}
    as="p"
    style="subtitle1">
    {children}
  </Text>
));

export default NavItem;

NavItem.displayName = 'NavItem';
