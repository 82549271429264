import { DynamicConnectButton } from '@dynamic-labs/sdk-react-core';
import { FC } from 'react';
import Box from '@/components/primitives/Box';

type Props = {};

export const ConnectWalletButton: FC<Props> = () => {
  return (
    <DynamicConnectButton>
      <Box
        style={{
          flex: '1',
          display: 'flex',
          justifyContent: 'flex',
        }}>
        <Box
          css={{
            minWidth: '160px',
            padding: 10,
            flex: 1,
            justifyContent: 'center',
            textWrap: 'nowrap',
            borderRadius: '8px',
            fontWeight: 700,
            background: 'black',
            color: 'white',
            transition: 'background 0.25s ease-in-out',
            '&:hover': {
              background: 'rgba(0,0,0,0.68)',
            },
          }}>
          Sign In
        </Box>
      </Box>
    </DynamicConnectButton>
  );
};
