import { EthereumWalletConnectors } from '@dynamic-labs/ethereum';
import { DynamicContextProvider } from '@dynamic-labs/sdk-react-core';
import { DynamicWagmiConnector } from '@dynamic-labs/wagmi-connector';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM, { hydrateRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { WagmiProvider } from 'wagmi';
import { ZeroDevSmartWalletConnectors } from '@dynamic-labs/ethereum-aa';
import { SoundContextProvider } from './context/SoundContextProvider';
import ToastContextProvider from '@/context/ToastContextProvider';
import { wagmiConfig } from '@/utils/wagmi';
import { router } from '@/routes';
import './globals.css';
import { privacyUrl, termsUrl } from '@/constants';

const rootElement = document.getElementById('react-root');
if (!rootElement) throw new Error('React root not found');
const root = ReactDOM.createRoot(rootElement);

const queryClient = new QueryClient();

const App = () => {
  return (
    <DynamicContextProvider
      settings={{
        environmentId: import.meta.env.VITE_DYNAMIC_ENVIRONMENT_ID,
        socialProvidersFilter: () => [],
        walletConnectors: [
          EthereumWalletConnectors,
          ZeroDevSmartWalletConnectors,
        ],
        recommendedWallets: [{ walletKey: 'coinbase' }],
        newToWeb3WalletChainMap: {
          primary_chain: 'evm',
          wallets: {
            evm: 'coinbase',
          },
        },
        privacyPolicyUrl: privacyUrl,
        termsOfServiceUrl: termsUrl,
      }}>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <ToastContextProvider>
            <DynamicWagmiConnector>
              <SoundContextProvider>
                <RouterProvider router={router} />
              </SoundContextProvider>
            </DynamicWagmiConnector>
          </ToastContextProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </DynamicContextProvider>
  );
};

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, <App />);
} else {
  root.render(<App />);
}
