import { FC } from 'react';
import React from 'react';
import { Box } from '../primitives';
import { RaritySummary as Summary } from '@/utils/cards';

type Props = {
  summary: Summary;
};

export const rarityColors = {
  SA: '#F0BE0D',
  UR: '#FF00FF',
  SR: '#FF4500',
  R: '#8316ED',
  C: '#1c2024',
};

export const rarityNames = {
  SA: 'Special Art',
  UR: 'Ultra Rare',
  SR: 'Super Rare',
  R: 'Rare',
  C: 'Common',
};

const pluralRarityNames = {
  SA: 'Special Art',
  UR: 'Ultra Rares',
  SR: 'Super Rares',
  R: 'Rares',
  C: 'Common',
};

const rarityOrder = {
  SA: 0,
  UR: 1,
  SR: 2,
  R: 3,
  C: 4,
};

export const RaritySummary: FC<Props> = ({ summary }) => {
  const rarities = Object.entries(summary)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, count]) => count > 0)
    .sort(
      ([rarityA], [rarityB]) =>
        rarityOrder[rarityA as keyof Summary] -
        rarityOrder[rarityB as keyof Summary]
    );

  const formatRarity = (rarity: keyof Summary, count: number) => (
    <Box key={rarity} as="span" css={{ color: rarityColors[rarity] }}>
      {count} {count > 1 ? pluralRarityNames[rarity] : rarityNames[rarity]}
    </Box>
  );

  return (
    <span>
      You got{' '}
      {rarities.map(([rarity, count], index) => (
        <React.Fragment key={rarity}>
          {index > 0 && index === rarities.length - 1 && ' and '}
          {index > 0 && index < rarities.length - 1 && ', '}
          {formatRarity(rarity as keyof Summary, count)}
        </React.Fragment>
      ))}
      {rarities.length > 0 ? ' card' : 'no cards'}
      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
      {rarities.reduce((sum, [_, count]) => sum + count, 0) > 1 ? 's' : ''}.
    </span>
  );
};
