import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import { useMediaQuery } from 'react-responsive';
import HamburgerMenu from './HamburgerMenu';
import NavItem from './NavItem';
import { AccountSidebar } from './AccountSidebar';
import { marketplaceUrl } from '@/constants';
import { useMounted } from '@/hooks';
import { Box, Flex } from '@/components/primitives';
import { ConnectWalletButton } from '@/components/ConnectWalletButton';

export const NAVBAR_HEIGHT = 81;
export const NAVBAR_HEIGHT_MOBILE = 80;

const Navbar = () => {
  const { isAuthenticated } = useDynamicContext();
  const isMobile = useMediaQuery({ query: '(max-width: 960px' });
  const isMounted = useMounted();

  if (!isMounted) {
    return null;
  }

  return isMobile ? (
    <Flex
      css={{
        height: NAVBAR_HEIGHT_MOBILE,
        px: '$4',
        width: '100%',
        borderBottom: '1px solid $gray4',
        zIndex: 999,
        background: '$slate1',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="center"
      justify="between">
      <Box css={{ flex: 1 }}>
        <Flex align="center">
          <a href={`/`}>
            <Box css={{ width: 46, height: 36, cursor: 'pointer' }}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src="https://res.cloudinary.com/niftykit/image/fetch/f_auto/https://storage.niftykit.com/ipfs/bafkreiay4gbdudm2ymep6luwinyj4sp63soljmjghxysouqo3qlw7n5osm"
                width={36}
                height={36}
                alt="Kaikai Kiki Marketplace"
              />
            </Box>
          </a>
        </Flex>
      </Box>
      <Flex align="center" css={{ gap: '$3' }}>
        <HamburgerMenu key={`-hamburger`} />
      </Flex>
    </Flex>
  ) : (
    <Flex
      css={{
        height: NAVBAR_HEIGHT,
        px: '$5',
        '@xl': {
          px: '$6',
        },
        width: '100%',
        mx: 'auto',
        borderBottom: '1px solid $gray4',
        zIndex: 999,
        background: '$neutralBg',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
      align="center"
      justify="between">
      <Box
        css={{
          flex: 'unset',
          '@bp1300': {
            flex: 1,
          },
        }}>
        <Flex align="center">
          <a href={`/`}>
            <Box css={{ minWidth: 36, cursor: 'pointer' }}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src="https://res.cloudinary.com/niftykit/image/fetch/f_auto/https://storage.niftykit.com/ipfs/bafkreiay4gbdudm2ymep6luwinyj4sp63soljmjghxysouqo3qlw7n5osm"
                width={36}
                height={36}
                alt="Kaikai Kiki Marketplace"
              />
            </Box>
          </a>
          <Flex
            align="center"
            css={{
              gap: '24px',
              ml: '$4',
            }}>
            <a href={marketplaceUrl}>
              <NavItem>Home</NavItem>
            </a>
            <a href={`${marketplaceUrl}/collections/trending`}>
              <NavItem>Collections</NavItem>
            </a>
            <a href={`${marketplaceUrl}/drops`}>
              <NavItem active={true}>Drops</NavItem>
            </a>
            <a href={`${marketplaceUrl}/leaderboard`}>
              <NavItem>Leaderboard</NavItem>
            </a>
          </Flex>
        </Flex>
      </Box>
      <Box css={{ flex: 1, px: '$5' }}></Box>

      <Flex
        css={{
          gap: '$3',
          flex: 'unset',
          '@bp1300': {
            flex: 1,
          },
        }}
        justify="end"
        align="center">
        <Flex css={{ gap: '$5', mr: 12 }}></Flex>

        {isAuthenticated ? (
          <AccountSidebar />
        ) : (
          <Box css={{ maxWidth: '185px' }}>
            <ConnectWalletButton />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default Navbar;
