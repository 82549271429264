import { createContext, ReactNode, useContext } from 'react';
import useSound from 'use-sound';

export type Sound = {
  playFlip: () => void;
  playPackOpen: () => void;
  playR: () => void;
  playSA: () => void;
  playSR: () => void;
  playUR: () => void;
};

const SoundContext = createContext<Sound>({
  playFlip: () => {},
  playPackOpen: () => {},
  playR: () => {},
  playSA: () => {},
  playSR: () => {},
  playUR: () => {},
});

type Props = {
  children: ReactNode;
};

export const SoundContextProvider = ({ children }: Props) => {
  const [playFlip] = useSound('/sounds/CardFlip.mp3');
  const [playPackOpen] = useSound('/sounds/Opening-Cards-Playing-Pack.mp3');
  const [playR] = useSound('/sounds/R.mp3', { volume: 0.4 });
  const [playSA] = useSound('/sounds/SA.mp3', { volume: 0.8 });
  const [playSR] = useSound('/sounds/SR.mp3', { volume: 0.8 });
  const [playUR] = useSound('/sounds/UR.mp3', { volume: 0.8 });

  return (
    <SoundContext.Provider
      value={{
        playFlip,
        playPackOpen,
        playR,
        playSA,
        playSR,
        playUR,
      }}>
      {children}
    </SoundContext.Provider>
  );
};

export const useSoundContext = () => {
  return useContext(SoundContext);
};
