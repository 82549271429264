import { FC, useEffect, useState } from 'react';
import { Text } from '@/components/primitives';

const getTimeRemaining = (goal: Date) => {
  const total = new Date(goal).getTime() - new Date().getTime();
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
};

const Countdown: FC<{
  inherit?: boolean;
  to?: Date;
  onComplete: () => void;
}> = ({ inherit = false, to, onComplete }) => {
  const [days, setDays] = useState('00');
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');

  useEffect(() => {
    const timeinterval = setInterval(() => {
      if (to) {
        const t = getTimeRemaining(to);
        setDays(('0' + t.days).slice(-2));
        setHours(('0' + t.hours).slice(-2));
        setMinutes(('0' + t.minutes).slice(-2));
        setSeconds(('0' + t.seconds).slice(-2));

        if (t.total <= 0) {
          clearInterval(timeinterval);
          onComplete();
        }
      }
    }, 1000);
    return () => clearInterval(timeinterval);
  }, [to, onComplete]);

  if (inherit)
    return (
      <span>
        <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:
        <span>{seconds}</span>
      </span>
    );

  return (
    <>
      <Text
        css={{
          marginBottom: 8,
          fontFamily: 'Poppins-Bold',
          fontSize: 60,
          lineHeight: '64px',
          textWrap: 'nowrap',
          '@md': {
            fontSize: 84,
            lineHeight: '80px',
          },
          '@lg': {
            fontSize: 100,
            lineHeight: '100px',
          },
        }}>
        <Text
          css={{
            fontSize: 60,
            fontWeight: 700,
            letterSpacing: '-4px',
            '@md': {
              fontSize: 84,
            },
            '@lg': {
              fontSize: 100,
            },
          }}>
          <span>{days}</span>:
        </Text>
        <Text
          css={{
            fontSize: 60,
            fontWeight: 700,
            letterSpacing: '-4px',
            '@md': {
              fontSize: 84,
            },
            '@lg': {
              fontSize: 100,
            },
          }}>
          <span>{hours}</span>:
        </Text>
        <Text
          css={{
            fontSize: 60,
            fontWeight: 700,
            letterSpacing: '-4px',
            '@md': {
              fontSize: 84,
            },
            '@lg': {
              fontSize: 100,
            },
          }}>
          <span>{minutes}</span>:
        </Text>
        <Text
          css={{
            minWidth: 70,
            display: 'inline-block',
            fontSize: 60,
            fontWeight: 700,
            letterSpacing: '-4px',
            '@md': { minWidth: 105, fontSize: 84 },
            '@lg': { minWidth: 125, fontSize: 100 },
          }}>
          <span>{seconds}</span>
        </Text>
      </Text>
    </>
  );
};

export default Countdown;
