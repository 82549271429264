import {
  HTMLMotionProps,
  ValueAnimationTransition,
  useAnimate,
} from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import numeral from 'numeral';
import { getCardRarity } from '../../utils/cards';
import { rarityColors, rarityNames } from './RaritySummary';
import { Box, Flex, Text } from '@/components/primitives';
import { useSoundContext } from '@/context/SoundContextProvider';

const Card: React.FC<
  HTMLMotionProps<'div'> & {
    initialView?: boolean;
    cardName: string;
    cardTokenId: number;
    quantity: number;
    id: string;
    imageUrl: string;
    onFlipCard?: (i: string, v: boolean) => void;
    revealedAtStart?: boolean;
  }
> = ({
  initialView = false,
  custom,
  cardName,
  cardTokenId,
  quantity,
  id,
  imageUrl,
  onFlipCard,
  revealedAtStart = false,
}) => {
  const [view, setView] = useState(false);
  const ref = useRef(null);
  const [scope, animate] = useAnimate();
  const sounds = useSoundContext();
  const [autoRevealed, setAutoRevealed] = useState(false);
  const cardRarity = getCardRarity(cardTokenId);
  const disabled = quantity === 0;
  const language = cardTokenId <= 114 ? 'EN' : 'JP';

  useEffect(() => {
    if (initialView || custom !== undefined) {
      setView(true);
    }
  }, [initialView, custom]);

  useEffect(() => {
    const animationConfig: ValueAnimationTransition = {
      type: 'spring',
      duration: 0.5,
      stiffness: 60,
    };

    animate(
      scope.current,
      {
        rotateY: view
          ? revealedAtStart
            ? 0
            : -180
          : revealedAtStart
          ? -180
          : 0,
      },
      view
        ? {
            ...animationConfig,
            // If it's reveal all, add custom animation delay
            delay: autoRevealed ? 0 : custom * 0.2,
            onComplete: () => !autoRevealed && setAutoRevealed(true),
          }
        : animationConfig
    );
  }, [animate, autoRevealed, custom, scope, view, revealedAtStart]);

  return (
    <>
      <div
        ref={ref}
        className="card-wrapper"
        onClick={() => {
          if (disabled) return;

          setView(!view);
          onFlipCard && onFlipCard(id, !view);
          if (!view) {
            switch (cardRarity) {
              case 'SR':
                sounds.playSR();
                break;
              case 'R':
                sounds.playR();
                break;
              case 'SA':
                sounds.playSA();
                break;
              case 'UR':
                sounds.playUR();
                break;
              default:
                sounds.playFlip();
                break;
            }
          } else {
            sounds.playFlip();
          }
        }}>
        <div></div>
        <div></div>
        <div className="card-container">
          <div className="card">
            <div className="content" ref={scope}>
              <div className="front">
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src={
                    revealedAtStart
                      ? imageUrl
                      : '/images/cards/MFCTC01-front.png'
                  }
                  alt={`${cardName} card`}
                  width={258}
                  height={361}
                  style={{ opacity: disabled ? 0.1 : 1 }}
                />
              </div>
              <div className="back">
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src={
                    revealedAtStart
                      ? 'images/cards/MFCTC01-front.png'
                      : imageUrl
                  }
                  alt={`${cardName} front`}
                  width={258}
                  height={361}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {view && cardRarity ? (
        <Flex
          direction="column"
          justify="center"
          align="center"
          css={{ width: '100%' }}>
          <Text
            css={{
              paddingTop: '$3',
              color: disabled ? '#888888' : rarityColors[cardRarity],
              textTransform: 'uppercase',
              fontFamily: 'Poppins-Bold',
            }}>
            #{numeral(cardTokenId).format('000')} ({language})
          </Text>
          {quantity > 0 ? (
            <Text
              css={{
                padding: 0,
                color: rarityColors[cardRarity],
                textTransform: 'uppercase',
                fontFamily: 'Poppins-Bold',
              }}>
              {rarityNames[cardRarity]} - {quantity} Owned
            </Text>
          ) : (
            <Text
              css={{
                padding: 0,
                color: '#888888',
                textTransform: 'uppercase',
                fontFamily: 'Poppins-Bold',
              }}>
              Not Collected
            </Text>
          )}
        </Flex>
      ) : (
        <Box css={{ minHeight: 60 }}></Box>
      )}
    </>
  );
};

export default Card;
