import {
  EthereumRpcError,
  getMessageFromCode,
  errorCodes,
} from 'eth-rpc-errors';

const USER_REJECTED_CODES = [
  errorCodes.provider.userRejectedRequest,
  'ACTION_REJECTED',
];

// eslint-disable-next-line import/prefer-default-export
export const handleError = (e: EthereumRpcError<unknown>): string => {
  let error = e;

  if (e.code === errorCodes.rpc.internal) {
    error = (e.data as EthereumRpcError<unknown>) || e;
  }

  const msg =
    typeof error === 'string'
      ? error
      : error?.message ||
        getMessageFromCode(error?.code, '') ||
        'An error occurred. Please refresh the page and try again.';

  if (msg.includes('err: insufficient funds')) {
    return "You don't have enough balance in your wallet.";
  }
  if (msg.includes('User rejected the request')) {
    return 'User rejected the request.';
  }

  if (USER_REJECTED_CODES.includes(error?.code as any)) {
    return 'User denied transaction signature.';
  }

  return 'An error occurred. Please refresh the page and try again.';
};
